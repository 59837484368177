.fake-hit-card {
  margin-bottom: 1.25rem;
  border-radius: $border-radius;

  @include theme(light) {
    box-shadow: var(--card-box-shadow);
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1.75rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 2.5rem;
  }

  .responsive-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fake-card-strip {
    background-color: var(--card-background-color);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 0.35rem 0.5rem;
    height: 48px;
    margin-top: -1px;
  }

  .timer-progress-container {
    height: 2.688rem;
    background-color: $grey-11;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include theme(light) {
      background-color: var(--card-background-color);
    }
  }

  &.flash-deal {
    .fake-card-strip {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.challenger {
    .fake-card-strip {
      height: 53px;
    }
  }
}

.fake-fan-favourite-card {
  .fake-content-container {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background-color);
    border-radius: $border-radius;

    @include theme(light) {
      box-shadow: var(--card-box-shadow);
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include media-breakpoint-only(md) {
      padding-bottom: 2.748rem;
    }

    .fake-left-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 45%;
      }

      .fake-cover {
        width: 100%;
        padding-bottom: 56.25%;
        background-color: $grey-10;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @include media-breakpoint-up(md) {
          border-top-right-radius: 0;
        }

        @include media-breakpoint-up(lg) {
          border-bottom-left-radius: $border-radius;
        }

        @include theme(light) {
          background-color: $grey-3;
        }
      }
    }

    .fake-right-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 13rem;

      @include media-breakpoint-up(md) {
        width: 55%;
        padding-bottom: 0;
      }
    }
  }
}
