.lang-ru {

  // GLOBAL
  .cart-btn {
    b,
    p {
      font-size: 0.875rem;

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }

  // NAVBAR
  .navbar {
    font-size: 0.6875rem;

    @include media-breakpoint-up(xl) {
      font-size: 0.8125rem;
    }
  }

  .secondary-navbar {
    .secondary-nav-link {
      @include media-breakpoint-only(lg) {
        padding: 0.5rem 0.25rem;
      }
    }
  }
}
