.progress-circle {
  position: relative;

  .progress-circle-foreground {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: $black;
    fill: transparent;
  }

  .progress-circle-background {
    fill: $white;
    // stroke: rgba($black, 0.2);
    stroke: $grey-3;
  }

  .circle-value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $black;

    .percentage-value {
      font-weight: 700;
    }

    .percentage-text {
      font-weight: 400;
    }
  }

  &.progress-circle-small {
    .circle-value {
      .percentage-value {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      .percentage-text {
        font-size: 9px;
      }
    }
  }

  &.progress-circle-medium {
    .circle-value {
      .percentage-value {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }

      .percentage-text {
        font-size: 12px;
      }
    }
  }

  &.progress-circle-large {
    .circle-value {
      .percentage-value {
        font-size: 2rem;
        line-height: 2rem;
      }

      .percentage-text {
        font-size: 15px;
      }
    }
  }
}
