@import "Styles/_chunk";

.age-gating {
  position: relative;
  padding: 1rem;
  background: $grey-11;
  width: 100%;
  margin: 0 auto !important;
  border-radius: $border-radius-sm;

  @include theme(light) {
    background-color: var(--site-background-color) !important;
    color: var(--site-font-color) !important;
  }

  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(xl) {
    width: 50%;
  }

  @include theme(light) {
    background: $white;
  }

  .ageratings-container {
    @include theme(light) {
      box-shadow: none;
    }
  }

  .failed-age {
    border: 1px solid $error-core;
  }

  .confirmation {
    font-size: 0.8rem;
  }

  .submit-age {
    font-weight: bold;
  }

  .form-control {
    background: $white;
    border-color: var(--input-border-color);
    border-radius: $border-radius-sm;
  }
}

.full-age-gate {
  width: 100% !important;
  background-color: $site-background-color-light !important;
  color: var(--site-font-color) !important;
}

.hitcard-bg {
  background-color: $site-background-color-light !important;
  color: var(--site-font-color) !important;
}
