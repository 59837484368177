.fanatical-general-page {
  padding: 3rem 0;

  .fanatical-general-page-content {
    background-color: var(--card-background-color);
    padding: 1rem;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 3rem;
    }

    @include theme(light) {
      box-shadow: var(--card-box-shadow);
    }
  }

  .fanatical-general-page-content-preference-centre {
    background-color: var(--card-background-color);
    padding: 0;
    box-shadow: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  p {
    span.highlight-text {
      font-weight: 700;
    }

    a {
      color: $text-white;
      text-decoration: underline;

      @include theme(light) {
        color: var(--site-font-color);
      }
    }
  }

  .general-form-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    .form-group {
      .form-helper-text {
        margin-top: 0.375rem;
        font-size: 14px;
        color: $error-core;
        font-weight: 700;
      }

      .form-field-error {
        border-color: $error-core !important;
        background-color: $red-50;
      }
    }

    .input-success-message {
      font-weight: 700;
      color: $success-core;
    }
  }

  .general-faq {
    margin-bottom: 0.75rem;

    .faq-answer {
      margin: 0.5rem 0;
    }

    .btn-general-faq {
      color: var(--brand-core, $primary-core);
      padding: 0;
      margin: 0;
      border: 0;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .cta-group-container {
    display: flex;
    flex-direction: column;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .cta-container {
      padding: 0.5rem;

      @include media-breakpoint-up(md) {
        padding: 0 0.5rem;
      }

      .btn {
        width: 100%;
        min-width: 140px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}

.fanatical-general-page-preference-centre {
  padding: 0;

  .container {
    padding: 0;
  }
}
