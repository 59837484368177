.quick-links {
  .quicklinks-container {
    --flex-gap: 1rem;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--flex-gap);

    .quick-link {
      --flex-items: 2; // Number of columns

      flex-grow: 1;
      width: calc((100% / var(--flex-items)) - (((var(--flex-items) - 1) / var(--flex-items)) * var(--flex-gap)));

      @include media-breakpoint-up(md) {
        --flex-items: 3;
      }

      @include media-breakpoint-up(lg) {
        --flex-items: 4;
      }

      @include media-breakpoint-up(xl) {
        --flex-items: 5;
      }

      a {
        height: 3.125rem;
        background-color: $card-background-color-dark;
        border-radius: $border-radius-sm;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $text-white;
        font-size: 0.875rem;
        font-weight: 400;
        transition: all 0.2s ease-out;
        text-align: center;

        @include theme(light) {
          background-color: $grey-3;
          color: var(--site-font-color);
          font-weight: 700;
        }

        &:hover {
          background-color: $black;

          @include theme(light) {
            background-color: $grey-4;
          }
        }
      }
    }

    .extra-quick-link {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .last-two-quick-link {
      @include media-breakpoint-only(lg) {
        display: none;
      }
    }
  }
}
