.lang-pl {

  // GLOBAL
  .email-subscribe-box-large {
    .email-title-container {
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }

  // NAVBAR
  .secondary-navbar {
    .secondary-nav-link {
      @include media-breakpoint-only(lg) {
        padding: 0.5rem;
      }
    }
  }

  // CHECKOUT
  .checkout {
    .basket-summary {
      .btn-payment {
        height: auto;
        min-height: 44px;
        white-space: normal;
      }
    }
  }

  // NEW PRODUCT PAGE

  .new-product-page {
    .new-product-commerce {
      .price-container {
        .price {
          @include media-breakpoint-only(lg) {
            font-size: 1.625rem;
          }
        }

        .was-price {
          @include media-breakpoint-only(lg) {
            font-size: 1.5rem;
          }
        }

        .saving-percentage {
          @include media-breakpoint-only(lg) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .redeem-page-birthday {
    .gift-theme-header {
      .header-area {
        .header-title {
          @include media-breakpoint-up(md) {
            font-size: 1rem;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.8rem;
          }

          @include media-breakpoint-up(lg) {
            font-size: 2em;
          }
        }

        .subheader-area {
          font-size: 0.6rem;

          @include media-breakpoint-up(md) {
            font-size: 0.8rem;
            margin-top: 0;
          }
        }
      }
    }
  }
}
