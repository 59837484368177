.progress {
  background-color: $grey-14;
  height: 1.2rem;
  width: 100%;
  border-radius: 10px;
  position: relative;

  @include theme(light) {
    background-color: $grey-4;
  }

  .progress-bar {
    height: 100%;
    font-weight: 400;
    color: $grey-2;

    &.star-deal {
      background-color: $stardeal-mid;
    }

    &.flash-deal {
      background-color: $red-600;
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  }

  .progress-timer {
    text-transform: uppercase;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-shadow: 1px 0 5px rgba($grey-14, 0.5), 1px 0 5px rgba($grey-14, 0.5);
    padding: 0 10px;
    margin-top: 0;
  }

  .progress-timer-overlay {
    position: absolute;
    width: 100%;
  }
}
