.lang-zh-hant {

  // NEW PRODUCT PAGE
  .new-product-page {
    .product-timer-container {
      .bundle-timer {
        .number-container {
          .number {
            div {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
