.quicklook-modal {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: -30px;
  max-width: 500px;
  font-weight: 400;

  .reduce-margin {
    margin-bottom: -1.5rem;
  }

  @media #{$retina-screen} {
    font-weight: inherit;
  }

  @include media-breakpoint-up(md) {
    max-width: 600px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 900px !important;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    z-index: 2;
  }

  .modal-body {
    padding: 1.5rem !important;
  }

  .quicklook-slider-container {
    .slider-main-title {
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(xxl) {
        font-size: 32px;
      }
    }

    .paragraph-small {
      text-align: center;
    }

    .disclaimer {
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: $grey-5;
    }

    .key-points {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .point {
      width: 31%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .point-dot {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .break-dot {
      background-color: $grey-4;
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }

    .cover-container {
      position: relative;

      .hover-content {
        position: absolute;
        background-color: rgba(black, 0.9);
        width: 100%;
        height: 100%;
        color: $text-white;
        display: none;
        text-align: center;
        padding: 10px;
        top: 0;
        left: 0;
      }

      &:hover {
        .hover-content {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;

      .btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 700;
        height: 40px;
        letter-spacing: 0.25px;
        text-align: center;
        font-size: 1rem;
        margin: 1px 5px;
      }

      .cart-btn b {
        font-size: 1rem;
      }

      .btn-bundle {
        color: var(--brand-core, $primary-core);
        font-weight: 400;
        height: 44px;
        display: flex;
        align-items: center;
        margin-right: 1rem;
        font-size: 15px;
      }
    }

    .featured-image-container {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .terms-and-conditions {
      text-align: center;
      color: $font-color-muted;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      font-size: 12px;
      font-weight: 400;
    }

    &.single-slide {
      .buttons-container {
        margin-top: 1rem;
      }

      .slick-track {
        display: flex;
        justify-content: space-around;
      }
    }

    .SwiperCarousel {
      margin-bottom: 4rem;

      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      .slick-slide {
        padding: 0 5px !important;
      }
    }
  }

  .slick-track {
    margin-top: 5px;
  }

  .slick-slide {
    max-width: 40%;
  }

  .slide-container {
    .slide {
      width: 95%;
      margin: 0 auto;

      .responsive-image {
        width: 100%;
      }
    }
  }

  .arrow-btn-container {
    &.btn-right {
      @include media-breakpoint-up(xl) {
        margin-right: -20px;
      }
    }

    &.btn-left {
      @include media-breakpoint-up(xl) {
        margin-left: -20px;
      }
    }
  }

  .quicklook-key-points {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .left-slash {
      background: linear-gradient(-66deg, white 0%, white 50%, transparent 50%, transparent 100%);
      width: 35px;
      flex-shrink: 0;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .right-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 50%, white 50%, white 100%);
      width: 35px;
      flex-shrink: 0;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .middle-slash {
      background: linear-gradient(-66deg, white 0%, white 45%, transparent 45%, transparent 55%, white 55%, white 100%);
      width: 40px;
      flex-shrink: 0;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .poin-box {
      background-color: $white;
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 13px;
      text-align: center;

      @include media-breakpoint-up(sm) {
        width: 30%;
      }

      &.box-one {
        @include media-breakpoint-down(xs) {
          padding-bottom: 0;
        }
      }

      &.box-two {
        @include media-breakpoint-down(xs) {
          padding: 0.25rem 0;
        }
      }

      &.box-three {
        @include media-breakpoint-down(xs) {
          padding-top: 0;
        }
      }
    }
  }

  .swiper-carousel-container {
    margin-bottom: -1rem;

    .swiper {
      padding: 1rem 0 3rem 0;
    }
  }
}

.quicklook-btn-container {
  display: flex;
  justify-content: center;
}

.quicklook-btn {
  display: flex;
  align-items: center;

  svg {
    margin-top: 2px;
    margin-right: 4px;
  }

  .age-gating {
    @include theme(light) {
      background: $white;
    }
  }
}
