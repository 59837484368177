.mobile-nav {
  .mobile-login-container {
    button {
      text-align: left;
      text-transform: uppercase;
      font-weight: 400;
      color: $text-white;

      @include theme(light) {
        color: $text-black;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .navbar-side-item {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px solid $grey-10;
    min-height: 60px;
    color: var(--site-font-color);
    height: auto !important;

    @include theme(light) {
      border-color: $grey-3;
    }

    &.black-friday {
      background-color: $black;
      color: $white;
      font-weight: 700;
    }

    &.first-item {
      background-color: $grey-13;

      @include theme(light) {
        background-color: $grey-1;
      }
    }

    .nav-link {
      width: 100%;
      cursor: pointer;

      &.active {
        color: var(--brand-core, $primary-core);
      }
    }

    &.collapse-links {
      background: $grey-14 !important;

      @include theme(light) {
        background: $grey-2 !important;
      }

      .logged-in-as {
        color: $grey-5;
        font-size: 12px !important;
        text-transform: uppercase;

        @include theme(light) {
          color: $blue-grey-600;
        }
      }

      .fa-heart {
        color: var(--brand-core, $primary-core);
      }
    }

    .star-deal-button {
      padding-left: 22px;
      background: url("data:image/svg+xml;utf8,<svg version='1.0' xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 4710 4710' preserveAspectRatio='xMidYMid meet'><g id='layer1' fill='rgb(38,198,218)' stroke='none'><path d='M3842 4658 c-60 -57 -1150 -1087 -1267 -1199 l-70 -67 -155 216 c-171 238 -189 254 -262 233 -20 -6 -45 -24 -56 -39 -10 -15 -50 -132 -87 -260 -38 -128 -72 -237 -76 -243 -4 -7 -332 136 -876 380 -544 245 -881 391 -900 391 -19 0 -44 -10 -62 -26 -28 -23 -31 -31 -31 -85 l0 -60 134 -142 c73 -78 370 -392 658 -696 l525 -555 -216 -157 c-213 -154 -241 -181 -241 -232 0 -28 33 -83 57 -95 10 -5 126 -42 258 -82 132 -39 241 -73 243 -74 1 -2 -173 -394 -388 -871 -254 -565 -390 -880 -390 -901 0 -20 10 -44 26 -63 23 -28 31 -31 85 -31 l60 0 162 153 c89 85 400 380 691 655 291 276 532 502 536 502 3 0 71 -91 151 -201 79 -111 157 -212 172 -225 41 -34 97 -33 137 3 26 24 40 60 104 276 40 136 75 250 78 252 3 3 395 -170 873 -385 603 -271 878 -390 902 -390 53 0 93 45 93 105 0 25 -5 55 -11 66 -6 11 -300 326 -655 701 -354 374 -644 684 -644 688 0 4 93 74 206 155 113 82 213 160 222 174 27 41 21 96 -14 132 -26 26 -66 41 -270 101 -131 39 -243 74 -248 79 -5 4 157 377 378 869 213 473 389 873 392 889 7 33 -17 85 -45 101 -11 5 -43 10 -71 10 -48 0 -55 -3 -108 -52z m-954 -1560 c7 -7 12 -23 12 -36 0 -12 -65 -168 -145 -345 l-145 -322 73 -75 c228 -234 427 -451 427 -465 0 -30 -20 -55 -44 -55 -13 0 -169 65 -347 145 l-323 146 -50 -49 c-264 -252 -446 -421 -469 -437 -37 -24 -77 -8 -77 30 0 14 65 171 145 348 l145 321 -24 26 c-13 14 -119 126 -236 250 -117 124 -219 235 -227 247 -18 26 -9 59 18 74 16 8 76 -15 345 -136 180 -80 332 -145 339 -143 8 2 129 112 269 246 257 243 282 262 314 230z'/></g></svg>") 0 7px / 20px 20px no-repeat;
      background-position: inherit;
    }

    &.side-dropdown {
      justify-content: space-between;

      &:hover {
        color: $text-white;

        @include theme(light) {
          color: var(--site-font-color);
        }
      }
    }
  }
}

.mobile-cart-items {
  padding: 0;
  white-space: nowrap;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  height: 67px;

  a {
    color: $text-white;
    padding: 0 0.5rem !important;

    @include theme(light) {
      color: var(--site-font-color);
    }

    @include media-breakpoint-up(md) {
      padding: 0 1rem !important;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 0.75rem !important;
    }
  }
}

.MobileBrowseSideBar__launchButton {
  padding-right: 0.5rem !important;
  color: var(--site-font-color);

  svg {
    width: 17px !important;
    height: 17px;
  }
}

.MobileAccountSideBar__launchButton {
  color: var(--site-font-color);
}

.MobileSideBar {
  .side-bar {
    overflow: hidden;
    background-color: $grey-1;
  }

  .MobileSideBar__title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .MobileSideBar__titleContainer {
    display: flex;
    align-items: baseline;
    color: $font-color-dark;
    padding: 0.5rem;
    justify-content: space-between;
    border-bottom: 3px solid $grey-3;

    @include media-breakpoint-up(sm) {
      padding: 0.5rem 1rem;
    }

    .MobileSideBar__exitButtonContainer {
      button {
        border: none;
        background: none;
        padding: 0;

        svg {
          color: $grey-5;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .MobileSideBar__subSideBarTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $font-color-dark;
    background-color: $site-background-color-light;
    padding: 1rem;
    border-bottom: 3px solid $grey-3;

    .MobileSideBar__subSideBarTitleContainer__mainMenuButton {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: $font-color-dark;

      svg {
        margin-right: 5px;
      }
    }

    .MobileSideBar__subSideBarTitleContainer__exitButton {
      svg {
        color: $grey-5;
        width: 20px;
        height: 20px;
      }
    }
  }

  .theme-toogle-container {
    width: 100%;
    padding-left: 0.5rem;
    min-height: 60px;

    @include media-breakpoint-up(sm) {
      padding-left: 1rem;
    }

    &:hover,
    &:focus {
      text-decoration: none;

      svg {
        color: $grey-5;
      }
    }

    .icon-container {
      margin-right: 0.5rem;
    }

    .text-container {
      font-weight: 400;
      font-size: 1rem;
      color: $font-color-dark;
    }

    .switch-container {
      margin-left: auto;
    }
  }

  .MobileSideBar__navItemsContainer {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .MobileSideBar__navItem {
    display: flex;
    align-items: center;
    padding: 0.5rem !important;
    margin: 0;
    font-weight: 400;
    min-height: 60px;
    height: auto !important;
    color: $font-color-dark !important;
    background-color: $site-background-color-light;
    font-size: 1rem !important; //prevents getting overriden by nav-link (bootstrap)

    @include media-breakpoint-up(sm) {
      padding: 1rem !important;
    }

    svg {
      color: $grey-5;
      width: 16px;
    }

    .nav-link {
      width: 100%;
      cursor: pointer;

      &.active {
        color: var(--brand-core, $primary-core);
      }
    }

    .star-deal-button {
      padding-left: 22px;
      background: url("data:image/svg+xml;utf8,<svg version='1.0' xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 4710 4710' preserveAspectRatio='xMidYMid meet'><g id='layer1' fill='rgb(38,198,218)' stroke='none'><path d='M3842 4658 c-60 -57 -1150 -1087 -1267 -1199 l-70 -67 -155 216 c-171 238 -189 254 -262 233 -20 -6 -45 -24 -56 -39 -10 -15 -50 -132 -87 -260 -38 -128 -72 -237 -76 -243 -4 -7 -332 136 -876 380 -544 245 -881 391 -900 391 -19 0 -44 -10 -62 -26 -28 -23 -31 -31 -31 -85 l0 -60 134 -142 c73 -78 370 -392 658 -696 l525 -555 -216 -157 c-213 -154 -241 -181 -241 -232 0 -28 33 -83 57 -95 10 -5 126 -42 258 -82 132 -39 241 -73 243 -74 1 -2 -173 -394 -388 -871 -254 -565 -390 -880 -390 -901 0 -20 10 -44 26 -63 23 -28 31 -31 85 -31 l60 0 162 153 c89 85 400 380 691 655 291 276 532 502 536 502 3 0 71 -91 151 -201 79 -111 157 -212 172 -225 41 -34 97 -33 137 3 26 24 40 60 104 276 40 136 75 250 78 252 3 3 395 -170 873 -385 603 -271 878 -390 902 -390 53 0 93 45 93 105 0 25 -5 55 -11 66 -6 11 -300 326 -655 701 -354 374 -644 684 -644 688 0 4 93 74 206 155 113 82 213 160 222 174 27 41 21 96 -14 132 -26 26 -66 41 -270 101 -131 39 -243 74 -248 79 -5 4 157 377 378 869 213 473 389 873 392 889 7 33 -17 85 -45 101 -11 5 -43 10 -71 10 -48 0 -55 -3 -108 -52z m-954 -1560 c7 -7 12 -23 12 -36 0 -12 -65 -168 -145 -345 l-145 -322 73 -75 c228 -234 427 -451 427 -465 0 -30 -20 -55 -44 -55 -13 0 -169 65 -347 145 l-323 146 -50 -49 c-264 -252 -446 -421 -469 -437 -37 -24 -77 -8 -77 30 0 14 65 171 145 348 l145 321 -24 26 c-13 14 -119 126 -236 250 -117 124 -219 235 -227 247 -18 26 -9 59 18 74 16 8 76 -15 345 -136 180 -80 332 -145 339 -143 8 2 129 112 269 246 257 243 282 262 314 230z'/></g></svg>") 0 7px / 20px 20px no-repeat;
      background-position: inherit;
      color: black;
    }

    &.side-dropdown {
      justify-content: space-between;

      &:hover {
        color: $font-color-light;

        @include theme(light) {
          color: $font-color-dark;
        }
      }
    }

    &--subItem {
      min-height: 50px;
      justify-content: space-between;

      svg {
        width: 8px;
      }
    }
  }

  .MobileSideBar__navItemStarDeal {
    color: $font-color-dark;
    background-color: $site-background-color-light;
    border: none;
    padding: 0.5rem !important;
    margin: 0;
    min-height: 60px;
    height: auto !important;

    @include media-breakpoint-up(sm) {
      padding: 1rem !important;
    }

    .star-deal-button {
      text-transform: capitalize;
      font-weight: 700;
      font-size: 1.3rem !important; //prevents getting overriden by nav-link (bootstrap)
      line-height: 1;
    }
  }

  .MobileSideBar__navItemBrowse {
    color: $font-color-dark;
    background-color: $site-background-color-light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    min-height: 60px;
    height: auto !important;
    padding: 0.5rem !important;
    font-size: 1.3rem !important; //prevents getting overriden by nav-link (bootstrap)
    font-weight: 700;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      padding: 1rem !important;
    }

    svg {
      width: 9px;
      height: 14px;
      color: $grey-5;
    }
  }

  .MobileSideBar__subSideBarTitle {
    color: $font-color-dark;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 0.5rem;
    display: flex;

    @include media-breakpoint-up(sm) {
      padding: 0.5rem 1rem;
    }
  }

  .MobileSideBar__loggedInAs {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 0;
    font-weight: 400;
    min-height: 60px;
    height: auto !important;
    background-color: $site-background-color-light;

    @include media-breakpoint-up(sm) {
      padding: 1rem !important;
    }

    .MobileSideBar__loggedInAs__title {
      color: $grey-5;
      text-transform: uppercase;
      font-size: 0.75rem;
    }

    .MobileSideBar__loggedInAs__email {
      text-transform: none;
      color: $font-color-dark;
      font-size: 1rem;
    }
  }
}

.MobileSiderBarV4 {
  .theme-toogle-container {
    width: 100%;
    padding-left: 0.5rem;
    min-height: 60px;
    background: $grey-14 !important;
    color: var(--site-font-color);
    border-bottom: 1px solid $grey-10;

    @include theme(light) {
      border-color: $grey-3;
      background: $grey-1 !important;
    }

    .logged-in-as {
      color: $grey-5;
      font-size: 12px !important;
      text-transform: uppercase;

      @include theme(light) {
        color: $blue-grey-600;
      }
    }

    @include media-breakpoint-up(sm) {
      padding-left: 1rem;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    .icon-container {
      margin-right: 0.5rem;
    }

    .text-container {
      font-weight: 400;
      text-transform: uppercase;
    }

    .switch-container {
      margin-left: auto;
    }
  }
}
