.steam-modal {
  @include media-breakpoint-up(lg) {
    min-width: 820px !important;
  }

  .modal-content {
    background-color: $white;

    .modal-body {
      .steam-modal-title {
        text-align: center;
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: $text-black;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }
      }

      .steam-modal-form {
        width: 100%;

        .steam-modal-paragraph,
        .steam-profile-limited {
          text-align: center;
          color: $text-black;
        }

        .paragraph-bold {
          font-weight: 400;
        }

        .link-steam-button-container {
          padding-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .account-connection-modal,
          .steam-refresh-btn {
            width: auto;
            margin-bottom: 1rem;
          }

          .button,
          button {
            width: 100%;
            text-transform: uppercase;
            border-radius: 0;
            font-weight: 700;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-bottom: 1rem;

            svg {
              margin-right: 0.75rem;
            }
          }
        }
      }

      .steam-modal-questions {
        padding-top: 2rem;
        width: 100%;

        .question-answer {
          background-color: $grey-2;
          margin-bottom: 0.5rem;
          border-radius: 3px;
        }

        .faq-question {
          border-radius: 3px;
          background-color: $grey-2;
          border: none;
          color: $text-black;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 1.5rem;
          font-weight: 400;
          max-height: 40px;

          @include theme(light) {
            border: 1px solid $grey-3;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .faq-answer {
          margin-bottom: 0.5rem;
          padding: 1.5rem;
          padding-top: 0;
          padding-bottom: 1rem;
          font-weight: 400;
        }
      }
    }

    .close-button-container {
      svg {
        color: $text-black !important;
      }
    }
  }
}
