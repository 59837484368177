@import "Styles/_chunk";

.popups-container {
  position: fixed;
  bottom: 0;
  z-index: 1100;
  padding: 0.5rem;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
    top: 0;
    bottom: auto;
  }

  @include media-breakpoint-up(lg) {
    padding: 1rem;
  }

  .feedback-popup {
    position: relative;
    width: 100%;
    max-width: 100%;
    // background-color: rgba($white, 0.95);
    background-color: $white;
    border-radius: $border-radius-sm;
    margin-bottom: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
    color: $text-black;
    border: 1px solid $grey-3;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      width: 360px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }

    .feedback-header {
      border-bottom: 1px solid $grey-3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      color: $grey-8;

      .icon-title-container {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.75rem;

        .icon-background {
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey-3;
          border-radius: $border-radius-sm;
          margin-right: 0.5rem;
          color: $white;
          font-size: 12px;
        }
      }
    }

    .feedback-body {
      padding: 0.75rem;
      word-wrap: break-word;
    }

    .close-button-container {
      svg {
        color: $grey-4;
      }
    }

    &.success {
      .feedback-header {
        .icon-background {
          background-color: $success-core;
        }
      }
    }

    &.error {
      .feedback-header {
        .icon-background {
          background-color: $error-core;
          font-size: 10px;
        }
      }
    }

    &.info {
      .feedback-header {
        .icon-background {
          background-color: $info-core;
        }
      }
    }

    &.warning {
      .feedback-header {
        .icon-background {
          background-color: $warning-core;
        }
      }
    }

    &.fade-out {
      animation: fadeout 1s;
    }

    &.fade-out-pause {
      animation: fadeoutpause 1s;
      animation-delay: 4s;
    }
  }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}
@keyframes fadeoutpause {
  from { opacity: 1; }
  to { opacity: 0; }
}
