@import "Styles/_chunk";

.checkout {
  .payment-details-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    h2 {
      margin-bottom: 1rem;
    }
  }

  .payment-error {
    color: $error-core;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  // DESKTOP ORDER SUMMARY

  .order-summary {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      width: 35%;
    }

    @include media-breakpoint-up(xl) {
      width: 32%;
    }

    .summary-message {
      margin-bottom: var(--gutter-gap);
      font-size: var(--site-font-size-small);
    }

    .summary-box {
      background-color: var(--card-background-color);
      padding: 15px;
      margin-bottom: var(--gutter-gap);

      @include theme(light) {
        box-shadow: var(--card-box-shadow);
      }

      @include media-breakpoint-up(md) {
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        padding: 25px;
      }

      &.billing-summary {
        .address-display {
          .address-display-title {
            display: none;
          }

          .address-display-details-container {
            .address-display-buttons-container {
              margin-top: 0.5rem;

              .btn {
                color: var(--brand-core, $primary-core);
                font-weight: 400;
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  // BILLING ADDRESS FORM

  .address-form-container {
    .address-form {
      .manual-form-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        @include media-breakpoint-up(md) {
          margin-left: -0.75rem;
          margin-right: -0.75rem;
        }

        @include media-breakpoint-up(xl) {
          margin-left: -0.875rem;
          margin-right: -0.875rem;
        }

        @include media-breakpoint-up(xxl) {
          margin-left: -1rem;
          margin-right: -1rem;
        }

        .form-group {
          padding: 0 0.5rem;

          @include media-breakpoint-up(md) {
            padding: 0 0.75rem;
          }

          @include media-breakpoint-up(xl) {
            padding: 0 0.875rem;
          }

          @include media-breakpoint-up(xxl) {
            padding: 0 1rem;
          }

          &.address-fields-one,
          &.address-fields-two {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: 50%;
            }
          }

          &.city-fields,
          &.area-fields,
          &.post-code-fields {
            width: 100%;

            @include media-breakpoint-up(sm) {
              width: 50%;
            }

            @include media-breakpoint-up(xl) {
              width: 33.333%;
            }
          }
        }
      }

      iframe {
        background-color: $white;
        font-weight: 400;
        height: 38px !important;
        padding: 0.375rem 0.75rem;
        line-height: 1.6;
        color: $text-black;
        border: 1px solid var(--input-border-color) !important;
        border-radius: 3px;
      }

      .fieldHasError iframe,
      input.fieldHasError {
        border: 3px solid $error-core !important;
        border-radius: 0 !important;
      }
    }

    .complete-button-container {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;

      .applepay-checkout-btn {
        [type=button] {
          -webkit-appearance: -apple-pay-button;
          border-radius: 4px;
        }
      }
    }

    .ideal-dropdown-button {
      min-width: 200px;
      background-color: $white;
      border-color: $white;
      color: $grey-14;
      font-weight: 400;
      height: 44px;

      &:hover,
      &:active {
        background-color: $grey-2;
        border-color: $grey-2;
        color: $grey-14;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

    .complete-button {
      @include button-variant($primary-core, $primary-core);

      color: $grey-14;
      min-width: 200px;
      font-weight: 700;
      font-size: 0.9375rem;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:hover,
      &:active {
        color: $grey-14;
      }
    }

    // .paypal-button {
    //   background-color: $paypal-blue;
    //   border-color: $paypal-blue;
    //   color: $white;
    //
    //   &:hover,
    //   &:active {
    //     background-color: darken($paypal-blue, 10%);
    //     border-color: darken($paypal-blue, 10%);
    //     color: $white;
    //   }
    // }

    .qiwi-button {
      background-color: $qiwi-color;
      border-color: $qiwi-color;

      &:hover,
      &:active {
        background-color: darken($qiwi-color, 10%);
        border-color: darken($qiwi-color, 10%);
      }
    }

    // BILLING ADDRESS FORM

    .address-form-container {
      .address-form {
        .manual-form-container {
          display: flex;
          flex-wrap: wrap;
          margin-left: -0.5rem;
          margin-right: -0.5rem;

          @include media-breakpoint-up(md) {
            margin-left: -0.75rem;
            margin-right: -0.75rem;
          }

          @include media-breakpoint-up(xl) {
            margin-left: -0.875rem;
            margin-right: -0.875rem;
          }

          @include media-breakpoint-up(xxl) {
            margin-left: -1rem;
            margin-right: -1rem;
          }

          .form-group {
            padding: 0 0.5rem;

            @include media-breakpoint-up(md) {
              padding: 0 0.75rem;
            }

            @include media-breakpoint-up(xl) {
              padding: 0 0.875rem;
            }

            @include media-breakpoint-up(xxl) {
              padding: 0 1rem;
            }

            &.address-fields-one,
            &.address-fields-two {
              width: 100%;

              @include media-breakpoint-up(md) {
                width: 50%;
              }
            }

            &.city-fields,
            &.area-fields,
            &.post-code-fields {
              width: 100%;

              @include media-breakpoint-up(sm) {
                width: 50%;
              }

              @include media-breakpoint-up(xl) {
                width: 33.333%;
              }
            }
          }
        }
      }

      .address-form-buttons {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .summary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;

    .item-text {
      margin-right: 0.75rem;
    }

    &.voucher {
      color: $grey-4;

      @include theme(light) {
        color: $grey-5;
      }
    }
  }

  .summary-fees {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
    border-top: 2px solid $grey-10;

    @include theme(light) {
      border-color: $grey-3;
    }

    &.subtotal {
      font-weight: 700;
    }
  }

  .summary-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.75rem;
    font-size: 18px;
    font-weight: 700;
    border-top: 2px solid $grey-10;

    @include theme(light) {
      border-color: $grey-3;
    }

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }

    .total-text {
      text-transform: uppercase;
    }

    .total-price {
      color: var(--brand-core, $primary-core);
    }
  }

  .summary-gift-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    border-top: 2px solid $grey-10;

    .item-text {
      margin-right: 0.75rem;
      margin-top: 0.75rem;
    }

    .item-recipient {
      margin-top: 0.75rem;
    }

    @include theme(light) {
      border-color: $grey-3;
    }
  }

  // MOBILE DETAILS COLLAPSE

  .mobile-collapse {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: var(--gutter-gap);

    @include theme(light) {
      box-shadow: var(--card-box-shadow);
    }
  }

  .mobile-collapse-button {
    width: 100%;
    text-align: left;
    padding: 15px;
    border: 0;
    background-color: var(--card-background-color);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include theme(light) {
      color: var(--site-font-color);
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--card-background-color) !important;
      border-color: transparent !important;
      box-shadow: none !important;

      @include theme(light) {
        color: var(--site-font-color);
      }
    }

    h2 {
      margin: 0;
      font-size: 20px;
    }

    .price-arrow-container {
      display: flex;
      align-items: center;
    }

    .summary-price {
      color: var(--brand-core, $primary-core);
      font-size: 18px;
      font-weight: 700;
      margin-right: 1.5rem;
    }
  }

  .mobile-collapse-details {
    background-color: var(--card-background-color);
    padding: 15px;
    padding-top: 0;
  }

  // MOBILE BILLING DETAILS

  .mobile-billing-box {
    padding: 15px;
    background-color: var(--card-background-color);

    @include theme(light) {
      color: var(--site-font-color);
    }

    h2 {
      font-size: 20px;
    }

    .address-display {
      .address-display-title {
        display: none;
      }

      .address-display-details-container {
        display: flex;

        .address-display-details {
          flex-grow: 1;

          .setting-item-address {
            display: flex;
            flex-wrap: wrap;
            white-space: pre-wrap;

            div {
              &::after {
                content: ", ";
              }

              &:last-of-type {
                &::after {
                  content: "";
                }
              }
            }
          }
        }

        .address-display-buttons-container {
          flex-shrink: 0;

          .account-table-action-button {
            color: var(--brand-core, $primary-core);
            padding: 0;
            margin: 0;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.saved-wallet-button-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.address-success {
  color: $success-core;
  font-weight: 700;
  text-align: center;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

.payment-epic-account {
  display: flex;

  .connection-icon {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $steam-black;
  }

  img {
    width: 22px;
  }

  .btn {
    color: var(--brand-core, $primary-core);
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
}
