@import "Styles/_chunk";

.SortByDropdown {
  position: relative;
  flex-shrink: 0;

  .SortByDropdown__button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 0;
    margin: 0;
    padding: 0;
    color: var(--site-font-color);
    background-color: transparent;
    min-height: 40px;

    label {
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .SortByDropdown__options {
    position: absolute;
    z-index: 100;
    right: 0;
    min-width: 180px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $grey-3;
    border-radius: $border-radius-lg;
    box-shadow: var(--card-box-shadow);
    overflow: hidden;
    padding: 0.5rem;
    color: $font-color-dark;
    margin-top: 0.125rem;

    .SortByDropdown__options__option {
      border: 0;
      text-align: left;
      border-radius: $border-radius-sm;
      font-size: 0.875rem;
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      padding: 0 0.25rem;

      &:hover {
        background-color: $blue-500;
        color: $white;
      }

      .SortByDropdown__options__option__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
