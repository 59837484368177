/*
* The `Faux Block Link` pattern below allows you to make an entire block
* a link, whilst having links nested within in that block also clickable.
*
* The main link (i.e. the faux block link) is absolutely positioned across the entire
* block, any nested links have a little z-index bump to lift them on top. Nice.

<div class="faux-block-link">
    <a href="http://www.bbc.co.uk/news" class="faux-block-link__overlay-link">
      <h3>Heading</h3>
    </a>
    <p>This is some summary <a href="http://www.bbc.co.uk/sport">text</a></p>
</div>

Ripped from BBC Grandstand lib
*/

@mixin ie-background-fix {
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7) repeat 0 0;
}

.faux-block-link {
  position: relative;
}

.faux-block-link a,
.faux-block-link button,
.faux-block-link abbr[title] {
  position: relative;
  z-index: 3;
}

.faux-block-link .faux-block-link__overlay-link {
  position: static;
  text-decoration: none;

  &::before {
    bottom: 0;
    content: "";
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    z-index: 1;

    // only applies to IE 9
    .ie9 & {
      @include ie-background-fix;
    }
  }
}

// only applies to IE 10

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .faux-block-link .faux-block-link__overlay-link::before {
    @include ie-background-fix;
  }
}

/**
 * This class allows you to have a seperate overlay href rather than using :before
 * Useful if you have a position relative between the link and the container div where the .gs-o-faux-block-link
 * should overlay.
 */
.faux-block-link .faux-block-link__overlay {
  @include ie-background-fix;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  visibility: visible;
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

/**
 * Increased specificity so it trumps ".faux-block-link a"
 *
 * 1. IE Fix - Elements have a solid black background in high contrast mode on PC without this.
 */
.faux-block-link__overlay.faux-block-link__overlay {
  opacity: 0; /* [1] */
  position: absolute;
  z-index: 0;
}
