.hero-container {
  display: block;
  z-index: -1;
  position: absolute;
  width: 100%;

  .bg-img-container {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .bg-img {
    width: 150%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    max-width: 2200px;

    @include media-breakpoint-up(sm) {
      width: 140%;
      min-width: 940px;
    }

    @include media-breakpoint-up(md) {
      min-width: 1200px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 1540px;
      width: 120%;
    }

    @include media-breakpoint-up(xl) {
      min-width: 1800px;
      width: 100%;
    }

    @include media-breakpoint-up(xxl) {
      min-width: 2060px;
    }
  }
}

.mobile-takeover {
  position: relative;
  padding-bottom: 22.22%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .mobile-video-takeover {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.center-img-container {
  @include media-breakpoint-up(md) {
    margin: 10px 0 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: 10px 0 50px;
  }

  @include media-breakpoint-up(xl) {
    margin: 10px 0 70px;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 0 90px;
  }

  .center-img {
    width: 100%;
  }
}

.solid-hero-container {
  height: 120px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 107px;
  }

  @include media-breakpoint-up(lg) {
    height: 143px;
  }

  @include media-breakpoint-up(xl) {
    height: 170px;
  }

  @include media-breakpoint-up(xxl) {
    height: 200px;
  }

  .solid-center-img-container {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    a {
      width: 100%;
    }

    img {
      width: 100%;
      height: 120px;
      object-fit: contain;

      @include media-breakpoint-up(md) {
        height: 107px;
      }

      @include media-breakpoint-up(lg) {
        height: 143px;
      }

      @include media-breakpoint-up(xl) {
        height: 170px;
      }

      @include media-breakpoint-up(xxl) {
        height: 200px;
      }
    }
  }

  .hero-background-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .hero-background {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .hero-video-container {
    z-index: 1;
  }
}

.hero-front {
  position: relative;
  z-index: 2;
}

.strip-banner-under-takeover {
  background-color: $white;
  color: $black;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
  border: 0;
  min-height: 35px;
  font-size: var(--site-font-size-small);
  display: flex;
  align-items: center;

  @include theme(light) {
    background-color: $black;
    color: $white;
  }

  &:hover {
    color: $black;

    @include theme(light) {
      color: $white;
    }
  }

  .strip-banner-underlined-text {
    text-decoration: underline;
  }
}

// Styling for a 'deals' count button during the Red Hot Sale

.takeover-deals-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  &--footer {
    z-index: 4;
  }

  &--home,
  &--footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(md) {
      margin-left: 362px;
      margin-top: 98px;
      width: auto;
      height: auto;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 482px;
      margin-top: 132px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 574px;
      margin-top: 156px;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 674px;
      margin-top: 185px;
    }

    .takeover-deals-button {
      margin-top: 20%;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  &--onsale {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

    .takeover-deals-button {
      margin-top: 20%;

      @include media-breakpoint-up(sm) {
        margin-top: 21%;
      }

      @include media-breakpoint-up(md) {
        margin-top: 100px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 135px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 160px;
      }

      @include media-breakpoint-up(xxl) {
        margin-top: 190px;
      }
    }
  }

  .takeover-deals-button {
    background-color: #c70603;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: $border-radius;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    font-size: 15px;
    height: 30px;

    @include media-breakpoint-up(sm) {
      padding: 0 1rem;
      font-size: 16px;
      height: 44px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 0.75rem;
      font-size: 13px;
      height: 30px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 1rem;
      font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 1rem;
      font-size: 18px;
      height: 38px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 0 1.5rem;
      font-size: 20px;
      height: 44px;
    }

    span {
      margin-left: 0.375rem;
      font-size: 18px;

      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 26px;
        margin-left: 0.5rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 32px;
      }
    }

    span.sd-timer,
    span.label,
    span.colon {
      font-size: 15px;

      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 13px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 20px;
      }
    }

    span.label,
    span.colon {
      margin: 0;
    }

    span.colon {
      margin: 0 0.25rem;
    }
  }
}

.PreviewTakeOver {
  .PreviewTakeOver__mobilePreviewContainer {
    .PreviewTakeOver__mobilePreview {
      .center-img-container {
        margin: 0;
      }

      .mobile-takeover {
        display: block !important;
      }
    }
  }
}
