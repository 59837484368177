@import "Styles/_chunk";

.PageNotFound {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;

  @include media-breakpoint-up(xxl) {
    gap: 5rem;
    padding: 5rem 0;
  }

  .PageNotFound__header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 3rem;
    }

    @include media-breakpoint-up(lg) {
      gap: 6rem;
    }

    @include media-breakpoint-up(xl) {
      gap: 12rem;
    }

    .PageNotFound__header__details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.5rem;
      text-align: center;
      text-wrap: balance;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
        text-align: left;
        text-wrap: wrap;
      }

      .PageNotFound__header__details__heading {
        color: var(--brand-core, $primary-core);
        margin: 0;
        font-size: 1.5rem;

        @include media-breakpoint-up(md) {
          font-size: 1.75rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 2.25rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 2.5rem;
        }
      }

      .PageNotFound__header__details__text {
        margin-bottom: 0.5rem;
      }

      .PageNotFound__header__details__button {
        font-weight: 700;
      }
    }

    .PageNotFound__header__img {
      width: 240px;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 45%;
      }

      @include media-breakpoint-up(lg) {
        width: 42%;
      }

      @include media-breakpoint-up(xxl) {
        width: 45%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
