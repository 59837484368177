.lang-nl {

  // GLOBAL
  .cart-btn {
    b,
    p {
      font-size: 0.875rem;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }
    }
  }

  // CHECKOUT
  .checkout-modal {
    .checkout-modal-body {
      .btn-primary,
      .btn-secondary {
        font-size: 13px;
        height: 35px;
      }
    }
  }

  // BUNDLE PAGE
  .bundle-page {
    .bundle-header {
      .details-container {
        .button-container {
          .text-container {
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
        }
      }
    }
  }

  // EMAIL SUBSCRIBE BOX
  .email-subscribe-box-large {
    .email-title-container {
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }
}
