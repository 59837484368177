.web-push-modal {
  margin: 2rem auto;
  max-width: 500px !important;
  height: 100vh;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 100%;

  .modal-content {
    background: rgba(0, 0, 0, 0);
    margin-right: 32px;
    margin-left: 32px;
  }

  .web-push-container {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.25);
  }

  .image-container {
    width: 90px;
    height: 90px;
  }

  .text-container {
    gap: 8px;
    color: #000;
    text-align: center;

    .text-main {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 109.091% */
    }

    .text-subtext {
      font-weight: 400;
    }
  }

  .close-button-container {
    position: absolute !important;
    right: 16px !important;
    top: 16px !important;
    width: 24px !important;
    height: 24px !important;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .dont-allow-button {
      text-transform: uppercase;
      font-weight: 600;
      padding: 8px 16px;
    }

    .allow-button {
      text-transform: uppercase;
      font-weight: 600;
      padding: 8px 16px;
    }
  }
}
