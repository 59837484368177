#tooltip {
  position: relative;
  color: $text-black;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;

  @include theme(light) {
    color: $text-white;
    font-weight: 300;
  }
}

#tooltip-left-icon {
  width: max-content;
}

.mobile-sizing {
  font-size: 13px;
}

.arrow_box {
  position: absolute;
  background: white;
  padding: 5px;
  border-radius: 2px;
  z-index: 9999;
  top: 10px;

  @include theme(light) {
    background: $grey-16;
  }
}

.arrow_box::after,
.arrow_box::before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $white;
  border-width: 7px;
  margin-left: -10px;

  @include theme(light) {
    border-bottom-color: $grey-16;
  }
}

.arrow_box::before {
  border-color: rgba(242, 242, 242, 0);
  border-bottom-color: $white;
  border-width: 7px;
  margin-left: -11px;

  @include theme(light) {
    border-bottom-color: $grey-16;
  }
}

.tooltip-content {
  display: flex;
  flex-direction: row;

  div {
    align-self: center;
  }
}
