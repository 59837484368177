.algoliaPaginate {
  .page-link.active {
    background: $grey-2 !important;
    color: $text-black !important;

    @include theme(light) {
      background: $grey-2 !important;
      border-color: $grey-4 !important;
    }
  }

  .page-item.hidden {
    display: none;
  }
}
