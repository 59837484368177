.lang-es {
  .secondary-navbar .community-link {
    display: none;
  }

  // EMAIL SUBSCRIBE BOX
  .email-subscribe-box-large {
    .email-title-container {
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }
}
