.fan-grid-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include media-breakpoint-up(lg) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  // @include media-breakpoint-up(xl) {
  //   margin-left: -0.875rem;
  //   margin-right: -0.875rem;
  // }

  @include media-breakpoint-up(xxl) {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .one-column-row,
  .two-column-row,
  .three-column-row,
  .four-column-row,
  .five-column-row,
  .six-column-row,
  .seven-column-row {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include media-breakpoint-up(lg) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    // @include media-breakpoint-up(xl) {
    //   padding-left: 0.875rem;
    //   padding-right: 0.875rem;
    // }

    @include media-breakpoint-up(xxl) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  // One CARD ROW
  .one-column-row {
    width: 100%;

    &.small-row {
      @include media-breakpoint-up(lg) {
        width: 66.666%;
      }
    }
  }

  // TWO CARD ROW
  .two-column-row {
    width: 50%;
  }

  // THREE CARD ROW
  .three-column-row {
    width: 50%;

    @include media-breakpoint-up(md) {
      width: 33.333%;
    }

    &.sm-one-column-row:first-child {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  // FOUR CARD ROW
  .four-column-row {
    width: 50%;

    @include media-breakpoint-up(md) {
      width: 33.333%;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    &.md-two-column-row {
      @include media-breakpoint-only(md) {
        width: 50%;
      }
    }
  }

  // FIVE CARD ROW
  .five-column-row {
    width: 50%;

    @include media-breakpoint-up(sm) {
      width: 33.333%;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }
  }

  // SIX CARD ROW
  .six-column-row {
    width: 50%;

    @include media-breakpoint-up(sm) {
      width: 33.333%;
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    @include media-breakpoint-up(xl) {
      width: 16.666%;
    }
  }

  // Seven CARD ROW
  .seven-column-row {
    width: 50%;

    @include media-breakpoint-up(sm) {
      width: 33.333%;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }

    @include media-breakpoint-up(xl) {
      width: 14.286%;
    }
  }

  // EXTRA STYLING FOR DLC TEMPLATE

  &.dlc-template {
    @include media-breakpoint-down(sm) {
      .two-column-row {
        &.main-game-container,
        &.dlc-cards-container {
          width: 100%;
        }
      }
    }
  }

  // HITCARDS IN GRID

  &.hit-cards-grid {
    margin-bottom: -1.25rem;

    @include media-breakpoint-up(md) {
      margin-bottom: -1.75rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: -2.5rem;
    }

    .HitCardContainer,
    .fake-card-container {
      padding: 0;
      max-width: 100% !important;
    }
  }

  // CAROUSEL FALLBACK FOR SKELETON CARDS

  &.carousel-fallback {
    .HitCardContainer {
      padding: 0;
      max-width: 100% !important;

      .HitCard,
      .video-hit-card,
      .challenger-hit-card {
        margin: 0;
      }
    }
  }

  // CAROUSEL NOT CENTER ALIGNED

  &.left-align {
    justify-content: flex-start;
  }

  .HitCard.flash-hit-card .faux-block-link__overlay-link::before {
    bottom: 0 !important;
  }
}
