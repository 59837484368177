.lang-de {

  // GLOBAL
  .cart-btn {
    b,
    p {
      font-size: 0.875rem;

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }

  // CHECKOUT
  .checkout-modal {
    .checkout-modal-body {
      .btn-primary,
      .btn-secondary {
        font-size: 13px;
        height: 35px;
      }
    }
  }

  // EMAIL SUBSCRIBE BOX
  .email-subscribe-box-large {
    .email-title-container {
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }
}
