.bundle-page,
.pick-and-mix {
  .fan-grid-container {

    // SHARED
    .two-column-row,
    .three-column-row,
    .four-column-row,
    .five-column-row,
    .six-column-row,
    .seven-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct, {
        .cover-container {
          height: 210px;

          img {
            position: relative;
            object-fit: contain;
            height: 210px;
          }
        }

        .landscape-cover {
          height: 172px;

          img {
            height: 172px;
          }
        }
      }
    }

    .two-column-row,
    .three-column-row,
    .four-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(sm) {
            height: 310px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 310px;
            }
          }
        }

        .landscape-cover {
          @include media-breakpoint-up(sm) {
            height: 254px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 254px;
            }
          }
        }
      }
    }

    // TWO COLUMN ROW
    .two-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(md) {
            height: 420px;
          }

          @include media-breakpoint-up(lg) {
            height: 565px;
          }

          @include media-breakpoint-up(xl) {
            height: 675px;
          }

          @include media-breakpoint-up(xxl) {
            height: 800px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 420px;
            }

            @include media-breakpoint-up(lg) {
              height: 565px;
            }

            @include media-breakpoint-up(xl) {
              height: 675px;
            }

            @include media-breakpoint-up(xxl) {
              height: 800px;
            }
          }
        }

        // LANDSCAPE COVER
        .landscape-cover {
          @include media-breakpoint-up(md) {
            height: 340px;
          }

          @include media-breakpoint-up(lg) {
            height: 460px;
          }

          @include media-breakpoint-up(xl) {
            height: 548px;
          }

          @include media-breakpoint-up(xxl) {
            height: 648px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 340px;
            }

            @include media-breakpoint-up(lg) {
              height: 460px;
            }

            @include media-breakpoint-up(xl) {
              height: 548px;
            }

            @include media-breakpoint-up(xxl) {
              height: 648px;
            }
          }
        }
      }
    }

    // THREE COLUMN ROW
    .three-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(md) {
            height: 270px;
          }

          @include media-breakpoint-up(lg) {
            height: 370px;
          }

          @include media-breakpoint-up(xl) {
            height: 440px;
          }

          @include media-breakpoint-up(xxl) {
            height: 520px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 270px;
            }

            @include media-breakpoint-up(lg) {
              height: 370px;
            }

            @include media-breakpoint-up(xl) {
              height: 440px;
            }

            @include media-breakpoint-up(xxl) {
              height: 520px;
            }
          }
        }

        // LANDSCAPE COVER
        .landscape-cover {
          @include media-breakpoint-up(md) {
            height: 218px;
          }

          @include media-breakpoint-up(lg) {
            height: 299px;
          }

          @include media-breakpoint-up(xl) {
            height: 356px;
          }

          @include media-breakpoint-up(xxl) {
            height: 423px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 218px;
            }

            @include media-breakpoint-up(lg) {
              height: 299px;
            }

            @include media-breakpoint-up(xl) {
              height: 356px;
            }

            @include media-breakpoint-up(xxl) {
              height: 423px;
            }
          }
        }
      }
    }

    // FOUR COLUMN ROW
    .four-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(md) {
            height: 270px;
          }

          @include media-breakpoint-up(xl) {
            height: 320px;
          }

          @include media-breakpoint-up(xxl) {
            height: 380px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 270px;
            }

            @include media-breakpoint-up(xl) {
              height: 320px;
            }

            @include media-breakpoint-up(xxl) {
              height: 380px;
            }
          }
        }

        // LANDSCAPE COVER
        .landscape-cover {
          @include media-breakpoint-up(md) {
            height: 218px;
          }

          @include media-breakpoint-up(xl) {
            height: 260px;
          }

          @include media-breakpoint-up(xxl) {
            height: 310px;
          }

          img {
            @include media-breakpoint-up(md) {
              height: 218px;
            }

            @include media-breakpoint-up(xl) {
              height: 260px;
            }

            @include media-breakpoint-up(xxl) {
              height: 310px;
            }
          }
        }
      }
    }

    // FIVE COLUMN ROW
    .five-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(sm) {
            height: 200px;
          }

          @include media-breakpoint-up(md) {
            height: 270px;
          }

          @include media-breakpoint-up(lg) {
            height: 210px;
          }

          @include media-breakpoint-up(xl) {
            height: 250px;
          }

          @include media-breakpoint-up(xxl) {
            height: 300px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 200px;
            }

            @include media-breakpoint-up(md) {
              height: 270px;
            }

            @include media-breakpoint-up(lg) {
              height: 210px;
            }

            @include media-breakpoint-up(xl) {
              height: 250px;
            }

            @include media-breakpoint-up(xxl) {
              height: 300px;
            }
          }
        }

        // LANDSCAPE COVER
        .landscape-cover {
          @include media-breakpoint-up(sm) {
            height: 164px;
          }

          @include media-breakpoint-up(md) {
            height: 219px;
          }

          @include media-breakpoint-up(lg) {
            height: 170px;
          }

          @include media-breakpoint-up(xl) {
            height: 203px;
          }

          @include media-breakpoint-up(xxl) {
            height: 243px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 164px;
            }

            @include media-breakpoint-up(md) {
              height: 219px;
            }

            @include media-breakpoint-up(lg) {
              height: 170px;
            }

            @include media-breakpoint-up(xl) {
              height: 203px;
            }

            @include media-breakpoint-up(xxl) {
              height: 243px;
            }
          }
        }
      }
    }

    // SIX and SEVEN COLUMN ROW
    .six-column-row,
    .seven-column-row {
      .bundle-product-card,
      .PickAndMixCard--bundleProduct {
        .cover-container {
          @include media-breakpoint-up(sm) {
            height: 200px;
          }

          @include media-breakpoint-up(md) {
            height: 270px;
          }

          @include media-breakpoint-up(xl) {
            height: 250px;
          }

          @include media-breakpoint-up(xxl) {
            height: 280px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 200px;
            }

            @include media-breakpoint-up(md) {
              height: 270px;
            }

            @include media-breakpoint-up(xl) {
              height: 250px;
            }

            @include media-breakpoint-up(xxl) {
              height: 280px;
            }
          }
        }

        // LANDSCAPE COVER
        .landscape-cover {
          @include media-breakpoint-up(sm) {
            height: 164px;
          }

          @include media-breakpoint-up(md) {
            height: 218px;
          }

          @include media-breakpoint-up(xl) {
            height: 164px;
          }

          @include media-breakpoint-up(xxl) {
            height: 197px;
          }

          img {
            @include media-breakpoint-up(sm) {
              height: 164px;
            }

            @include media-breakpoint-up(md) {
              height: 218px;
            }

            @include media-breakpoint-up(xl) {
              height: 164px;
            }

            @include media-breakpoint-up(xxl) {
              height: 197px;
            }
          }
        }
      }
    }
  }
}
