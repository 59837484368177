.product-video-container {
  position: relative;
}

.product-video-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 80px;
  height: 66px;

  @include media-breakpoint-up(md) {
    width: 100px;
    height: 84px;
  }

  @include media-breakpoint-up(xxl) {
    width: 120px;
    height: 102px;
  }

  button {
    color: $white;

    &:hover {
      cursor: pointer;
      color: $white;
    }

    svg {
      filter: drop-shadow(0 0 10px $grey-14);
      height: 100% !important;
      width: 100% !important;
      object-fit: contain;
    }
  }
}
