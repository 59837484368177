$card-select-border: 3px;
$christmas-burgandy: #d7243f;
$birthday-yellow: #ffe02d;
$christmas-green:  #2ea82b;
$birthday-red: #fe2600;
$christmas-font-family: 'Dosis', 'Lato', sans-serif;
$birthday-font-family: 'Press Start 2P', sans-serif;
$thanksgiving-gold: #e9ad3f;
$thanksgiving-gold-header-top: #fedd01;
$thanksgiving-gold-header-bottom: #c59237;
$chinese-new-year-red: #b31a17;
$chinese-new-year-gold: #fdcf77;

.redeem-page {
  background-color: var(--site-background-color);
  color: $text-black;
  margin-bottom: -1.5rem;
  min-height: 112vh;

  .header-container {
    height: 220px;

    @include media-breakpoint-up(md) {
      height: 320px;
    }

    @include media-breakpoint-up(lg) {
      height: 400px;
    }

    @include media-breakpoint-up(xxl) {
      height: 450px;
    }
  }

  .redeem-content-container {
    padding-bottom: 1rem;
    padding-top: 1.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 2.5rem;
    }
  }

  .preview-container {
    border: 2px solid $success-core;
    border-radius: $border-radius;
    padding: 0.5rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 400;

    h1 {
      font-size: 1.125rem;
      text-transform: uppercase;
    }
  }

  .personal-message-container {
    text-align: center;
    justify-content: center;
    display: flex;

    h3 {
      width: 100%;
      font-size: 1rem;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        width: 80%;
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(md) {
        width: 60%;
      }
    }
  }

  .please-sign-in {
    text-align: center;
    font-weight: 400;
    margin-bottom: 2.5rem;
  }

  .under-section-margin {
    margin-bottom: 3rem;
  }

  .gift-products-container {
    margin-bottom: 1.5rem;
    margin-top: 2rem;

    .three-column-row {

      @include media-breakpoint-only(xs) {
        width: 70%;
      }
    }

    .two-column-row {
      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        width: 70%;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }

      @include media-breakpoint-up(xxl) {
        margin-bottom: 7rem;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }
  }

  .referral-title {
    h3 {
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .how-it-works-icons-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 3rem;
  }

  .chevron-container {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
    }
  }

  .referral-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 32%;
    }
  }

  .referral-chevron {
    color: var(--brand-core, $primary-core);
    font-size: 1rem;
  }

  .referral-circle {
    width: 120px;
    height: 120px;
    background-color: $grey-3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-black;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 140px;
      height: 140px;
    }

    .step-icon {
      width: 50px;
      height: 50px;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
      }

      img {
        height: 100%;
        width: auto;
        max-width: 100%;
      }

      &.cart-add {
        width: 54px;
        height: 54px;
        margin-right: 3px;
      }
    }
  }

  .referral-circle-title {
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-top: 1rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    width: 80%;
  }

  .referral-circle-subtitle {
    font-size: 15px;
    font-weight: 400;
    width: 80%;

    a {
      color: $text-black;
      text-decoration: underline;
    }
  }

  .error-container {
    text-align: center;
    font-weight: 700;
    color: $error-core;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .sign-in-accept-button-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    .btn {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  .gift-terms {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center;
  }

  .gift-box-container {
    width: 100%;
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    .gift-box {
      .gift {
        .redeem-product-card {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
          }

          .card-content {
            .product-name {
              display: none;
            }
          }
        }

        img {
          box-shadow: none;
        }
      }
    }

    .drm-container {
      img {
        box-shadow: none;
      }
    }
  }

  .closed {
    .redeem-product-card {
      margin-bottom: 0;
    }
  }

  .redeem-product-card {
    &:hover {
      cursor: pointer;
    }

    .card-content {
      .select-button-container {
        .btn-secondary {
          &:hover {
            background-color: $grey-7;
          }
        }
      }
    }

    &.fade-out {
      opacity: 0.7;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .envelope-wrapper {
    border: none;

    .click-to-reveal-container {
      position: absolute;
      z-index: 5;
      color: $chinese-new-year-gold;
      font-size: 1.75rem;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      top: 55%;
    }

    .envelope {
      .letter {
        background-color: unset;
        background-image: none;
        box-shadow: none;

        .letter-contents {
          width: 85%;

          @include media-breakpoint-up(lg) {
            width: 75%;
          }

          .redeem-product-card {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.redeem-page-christmas,
.redeem-page-birthday,
.redeem-page-thanksgiving,
.redeem-page-chinese-new-year {
  .gift-theme-header {
    background-position: right !important;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      background-position: center top !important;
    }

    .header-area {
      color: $text-white;
      font-weight: 700;
      padding: 0.5rem 2rem;
      border-radius: 10px;
      border: 4px solid transparent;
      background: linear-gradient(360deg, #29456c 85%, #29456c 100%), linear-gradient(to bottom, #2467a0, #183851);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;

      .header-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 4.375rem;
        text-align: center;
      }

      .subheader-area {
        text-align: center;
      }
    }
  }

  .sign-in-accept-button-container {
    button {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .gift-from-title {
    font-weight: 700;
    font-size: 1.2rem;
    padding-top: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-black;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      padding-top: 2rem;
    }
  }

  .personal-message-container {
    color: $text-black;
  }
}

.redeem-page-christmas {
  background-color: #d3dee6;
  background-image: url(https://cdn.fanatical.com/production/assets/gifting/christmas-snow.jpg);

  .brand-line {
    background-color: $christmas-burgandy;
  }

  .referral-chevron {
    color: $christmas-burgandy;
  }

  .referral-circle {
    background-color: $white;
  }

  .gift-theme-header {
    font-family: $christmas-font-family;

    .header-area {
      .header-title {
        font-weight: 700;
        font-size: 1.2rem;

        @include media-breakpoint-up(md) {
          font-size: 3rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 4.375rem;
        }
      }
    }
  }

  .sign-in-accept-button-container {
    button {
      background-color: $christmas-green;
      border-color: $christmas-green;
      color: $text-white !important;
    }
  }
}

.redeem-page-birthday {
  background-color: #c0f5fd;

  .brand-line {
    background-color: $birthday-red;
  }

  .referral-chevron {
    color: $birthday-red;
  }

  .referral-circle {
    background-color: $white;
  }

  .gift-theme-header {
    font-family: $birthday-font-family;

    .header-area {
      .header-title {
        font-size: 1.2rem;
        color: $birthday-yellow;

        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 3.5rem;
        }
      }

      .subheader-area {
        font-size: 0.6rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
          margin-top: -1rem;
        }
      }
    }
  }

  .sign-in-accept-button-container {
    button {
      background-color: $birthday-yellow;
      border-color: $birthday-yellow;
    }
  }
}

.redeem-page-thanksgiving {
  background-color: #c0f5fd;

  .brand-line {
    background-color: $thanksgiving-gold;
  }

  .referral-chevron {
    color: $thanksgiving-gold;
  }

  .referral-circle {
    background-color: $white;
  }

  .gift-theme-header {
    font-family: $birthday-font-family;

    .header-area {
      background: linear-gradient(360deg, $thanksgiving-gold 85%, $thanksgiving-gold 100%), linear-gradient(to bottom, $thanksgiving-gold-header-top, $thanksgiving-gold-header-bottom);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      color: $white;

      @include media-breakpoint-up(sm) {
        background-position: center top !important;
      }

      .header-title {
        font-size: 1.2rem;
        text-transform: none;

        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }

      .subheader-area {
        font-size: 1.2rem;

        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
          margin-top: -0.5rem;
        }
      }
    }
  }

  .sign-in-accept-button-container {
    button {
      background-color: $birthday-yellow;
      border-color: $birthday-yellow;
    }
  }
}

.redeem-page-chinese-new-year {
  background-color: $grey-2;

  .header-container {
    height: 100px;

    @include media-breakpoint-up(sm) {
      height: 128px;
    }

    @include media-breakpoint-up(md) {
      height: 173px;
    }

    @include media-breakpoint-up(lg) {
      height: 220px;
    }

    @include media-breakpoint-up(xl) {
      height: 266px;
    }

    @include media-breakpoint-up(xxl) {
      height: 320px;
    }
  }

  .brand-line {
    background-color: $chinese-new-year-red;
  }

  .referral-chevron {
    color: $chinese-new-year-red;
  }

  .referral-circle {
    background-color: $white;
  }

  .gift-theme-header {
    font-family: $birthday-font-family;
    background-position: top !important;
    background-size: contain !important;

    @include media-breakpoint-up(sm) {
      background-position: center !important;
      background-size: cover !important;
    }

    .header-area {
      display: none;
    }
  }

  .sign-in-accept-button-container {
    button {
      background-color: #fecf77;
      border-color: #fecf77;
    }
  }
}
