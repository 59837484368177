.wrapper {
  min-height: 100vh;
}

.content {
  flex: 1;
  min-height: calc(100vh + 150px);
}

.loading-placeholder.fullHeight {
  min-height: 110vh;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
