@import "Styles/_chunk";

.email-subscribe-input {
  button {
    border: 0;
    height: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  input[type="email"] {
    padding: 7px;
    height: 2rem;
    border-radius: 5px;
  }

  input[type="email"]::placeholder {
    color: $font-color-muted;
  }

  .form-control {
    border: 0;
  }

  .input-group {
    background-color: transparent !important;

    .input-group-append {
      button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
