.hitCardLabel {
  position: absolute;
  margin-left: -5px;
  margin-top: -5px;
  z-index: 10;
  top: 0;

  .label-content {
    padding: 3px 6px;
    padding-right: 0;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1px;

    .sd-timer {
      span {
        font-size: 8px;
      }

      .colon {
        padding-left: 0.15em;
        padding-right: 0.15rem;
      }
    }
  }

  .sd-timer {
    padding-left: 4px;
  }

  .label-triangle {
    width: 0;
    height: 0;
    border-top: 5px solid $white;
    border-left: 5px solid transparent;
  }

  .label-slash {
    width: 20px;
  }

  /* -66 deg is the exact same angle prevelant in the logo - do not change it
    the stop point for the slant (19% and 20%) is 1% offset to make it appear anti aliased */

  // --------------------
  // PRE ORDER LABEL
  // --------------------

  .label-preorder-container,
  .label-earlyaccess-container {
    .label-content {
      color: $black;
      background: $label-green;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-green 51%, $label-green 100%);
    }
  }

  .triangle-preorder,
  .triangle-earlyaccess {
    border-top-color: $label-green-dark;
  }

  // --------------------
  // STAR DEAL LABEL
  // --------------------

  .label-stardeal-container {
    .label-content {
      color: $black;
      background: $label-stardeal;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-stardeal 51%, $label-stardeal 100%);
    }
  }

  .triangle-stardeal {
    border-top-color: $label-stardeal-dark;
  }

  // --------------------
  // ENDING SOON LABEL
  // LAST CHANCE LABEL
  // FLASH DEAL LABEL
  // LOW STOCK LABEL
  // --------------------

  .label-endingsoon-container,
  .label-lastchance-container,
  .label-lowstock-container,
  .label-flashdeal-container {
    .label-content {
      color: $white;
      background: $label-red;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-red 51%, $label-red 100%);
    }
  }

  .triangle-endingsoon,
  .triangle-lastchance,
  .triangle-lowstock,
  .triangle-flashdeal {
    border-top-color: $label-red-dark;
  }

  // --------------------
  // JUST LANDED LABEL
  // BEST DISCOUNT LABEL
  // --------------------

  .label-landed-container,
  .label-bestdiscount-container {
    .label-content {
      color: $black;
      background: $label-amber;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-amber 51%, $label-amber 100%);
    }
  }

  .triangle-landed,
  .triangle-bestdiscount {
    border-top-color: $label-amber-dark;
  }

  // --------------------
  // DLC LABEL
  // --------------------

  .label-dlc-container {
    .label-content {
      color: $white;
      background: $label-purple;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-purple 51%, $label-purple 100%);
    }
  }

  .triangle-dlc {
    border-top-color: $label-purple-dark;
  }

  // --------------------
  // BEST EVER LABEL
  // --------------------

  .label-bestever-container {
    .label-content {
      color: $black;
      font-weight: 700;
      background: $label-primary;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-primary 51%, $label-primary 100%);
    }
  }

  .triangle-bestever {
    border-top-color: $label-primary-dark;
  }

  // --------------------
  // In Library LABEL
  // --------------------

  .label-inLibrary-container {
    .label-content {
      color: $white;
      background: $label-teal;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-teal 51%, $label-teal 100%);
    }
  }

  .triangle-inLibrary {
    border-top-color: $label-teal-dark;
  }

  // --------------------
  // In Steam Library LABEL
  // --------------------

  .label-inSteamLibrary-container {
    .label-content {
      color: $white;
      background: $steam-core;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $steam-core 51%, $steam-core 100%);
    }
  }

  .triangle-inSteamLibrary {
    border-top-color: $steam-core-dark;
  }

  // --------------------
  // BUNDLEFEST
  // --------------------

  .label-bundlefest-container {
    .label-content {
      color: $font-color-dark;
      background: $label-primary;
      font-weight: 700;
    }

    .label-slash {
      background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-primary 51%, $label-primary 100%);
    }
  }

  .triangle-bundlefest {
    border-top-color: $label-primary-dark;
  }
}

.ending-soon-page {
  .hitCardLabel {
    display: none;
  }
}
