.lang-fr {

  // EMAIL SUBSCRIBE BOX
  .email-subscribe-box-large {
    .email-title-container {
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }
  }

  // NEW PRODUCT PAGE
  .new-product-page {
    .new-product-commerce {
      .price-container {
        .price {
          @include media-breakpoint-only(lg) {
            font-size: 1.625rem;
          }
        }

        .was-price {
          @include media-breakpoint-only(lg) {
            font-size: 1.5rem;
          }
        }

        .saving-percentage {
          @include media-breakpoint-only(lg) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  // COUPON OFFER BANNER
  .valid-coupon-banner {
    .icon-container {
      .certificate-details {
        .off {
          font-size: 15px;
        }
      }
    }
  }
}
