@import "Styles/_chunk";

#account-delete-modal {
  input {
    border-radius: 8px;
  }

  .input-group-text {
    border: none !important;
    position: absolute !important;
    right: 0 !important;
    top: 7px !important;
    background: none !important;
    z-index: 10;

    .valid {
      color: $green-500;
    }

    .invalid {
      color: $red-500;
    }
  }

  .style-underlined {
    margin: 0;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid lightgray;
  }

  .modal-header span {
    border: none !important;
    position: absolute !important;
    right: 10px !important;
    top: 15px !important;
    background: none !important;
  }

  .icon-align {
    position: absolute;
    top: 50%;
    bottom: 50%;
    padding: 0;
    margin: 0;
  }
}
