.email-subscribe-modal {
  margin: 2rem auto;
  max-width: 680px !important;
  height: 100vh;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 100%;

  .modal-content {
    background: rgba(0, 0, 0, 0);
    margin-right: 32px;
    margin-left: 32px;
  }

  .email-subscribe-container {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.25);
  }

  .image-container {
    width: 90px;
    height: 90px;
  }

  .text-container {
    gap: 8px;
    color: #000;
    text-align: center;

    .text-main {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 109.091% */
    }

    .text-subtext {
      font-size: var(--site-font-size-regular);
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
    }
  }

  .close-button-container {
    position: absolute !important;
    right: 16px !important;
    top: 16px !important;
    width: 24px !important;
    height: 24px !important;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      border: 0;

      &:hover {
        cursor: pointer;
      }
    }

    input[type="email"] {
      padding: 7px;
      border-radius: 5px;
    }

    input[type="email"]::placeholder {
      color: $font-color-muted;
    }

    .input-group {
      width: 320px;

      .input-group-append {
        button {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  @media (max-width: 560px) {
    input[type="email"] {
      width: 100%;
      max-width: 320px;
      border-radius: 5px !important;
    }

    .input-group {
      width: 100% !important;

      .input-group-append {
        padding-top: 8px;
        width: 100%;

        button {
          border-radius: 5px;
          height: calc(1.6em + 0.75rem + 2px);
          width: 100%;
        }
      }
    }
  }
}
