@import "Styles/_chunk";

.CountdownTimer {
  &--cardTimer {
    .CountdownTimer__clock {
      height: 32px;
      border-radius: $border-radius-sm;
      background-color: var(--percentage-background-color);
      color: var(--site-font-color);
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      font-size: 13px;
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }

  &--endingSoon {
    .CountdownTimer__clock {
      background-color: $label-red-dark;
      color: $white;
    }
  }
}
