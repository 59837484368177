.video-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    max-width: 600px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 900px !important;
  }

  .slick-slider {
    .arrow-btn-container {
      .slick-disabled {
        svg {
          color: $grey-4 !important;

          &:focus,
          :hover,
          :active {
            color: $grey-4 !important;
          }
        }
      }

      .slick-arrow {
        width: 27px;

        @include media-breakpoint-up(md) {
          width: 36px;
        }

        svg {
          color: $grey-5;
        }
      }
    }

    ul.slick-dots button {
      background-color: $grey-4;
    }

    .mobile-friendly-dots {
      background-color: $grey-4;
    }

    .slick-active .mobile-friendly-dots {
      background-color: $grey-11 !important;
    }

    ul.slick-dots li.slick-active button {
      background-color: $grey-11 !important;
    }
  }

  .modal-content {
    border-radius: 0;

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 22px !important;

      @include media-breakpoint-up(sm) {
        padding: 30px !important;
        padding-top: 26px !important;
      }

      @include media-breakpoint-up(xl) {
        padding: 40px !important;
        padding-top: 32px !important;
      }

      .modal-title-container {
        margin-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: 400;
        text-align: center;
        display: none;

        @include media-breakpoint-up(sm) {
          display: block;
        }

        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
      }

      .video-container {
        width: 100%;
      }

      .divider {
        border-image: linear-gradient(to right, $grey-2, $grey-4, $grey-2) 1;
        border-style: solid;
        border-width: 1px;
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }

      .purchase-message-container {
        width: 100%;
        font-size: 1rem;
        color: $text-black;
        margin-top: 0.5rem;
      }

      .purchase-details-container {
        display: none;

        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          flex-direction: row;
          margin-top: 1.5rem;
          width: 100%;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 2rem;
        }

        .game-title {
          font-weight: 400;
          font-size: 1.25rem;
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: 1rem;
            font-size: 1.375rem;
          }
        }

        .price-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .price-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;

            .game-discount {
              background-color: $grey-4;
              color: $text-black;
              padding: 8px;
              font-size: 1rem;
              font-weight: 400;
              border-radius: $border-radius-sm;
              margin-right: 0.75rem;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;

              @include media-breakpoint-up(xl) {
                font-size: 1.125rem;
              }
            }

            .game-was-price {
              font-size: 1.25rem;
              color: $grey-5;
              text-decoration: line-through;
              margin-right: 0.75rem;
              font-weight: 400;
            }

            .game-current-price {
              color: $primary-core;
              font-weight: 700;
              font-size: 1.25rem;
              text-transform: uppercase;
            }
          }

          .btn-primary {
            display: flex !important;
            align-items: center;
            padding: 5px;

            .cart-btn-icon {
              background-color: $black;
              color: $white;
              border-radius: $border-radius;
              padding: 0.375rem 0.5rem;
              font-size: 15px;
            }

            .btn-text-container {
              text-transform: uppercase;
              font-weight: 700;
              font-size: 0.9375rem;
              padding: 0 0.5rem;

              @include media-breakpoint-up(xxl) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .close-button-container {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      @include media-breakpoint-up(md) {
        margin: 5px;
      }

      button {
        width: 100%;
        background: transparent;
        border: 0;
        padding: 0;
      }

      svg {
        color: $grey-4;
      }
    }
  }
}

.ModalDefault {
  position: relative;

  @include media-breakpoint-down(xs) {
    width: 360px !important;
    margin: 0.5rem auto !important;
  }

  .modal-content {
    padding: 0;
    border-radius: $border-radius !important;

    .modal-body {
      padding: 1.5rem !important;
      background-color: $white;
      border-radius: $border-radius !important;

      @include media-breakpoint-up(md) {
        padding: 2rem !important;
      }

      @include media-breakpoint-up(xxl) {
        padding: 2.5rem !important;
      }
    }

    .close-button {
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 30;
      margin: 5px;

      svg {
        color: $black !important;
      }
    }

    .close-button-container {
      svg {
        color: $black !important;
      }
    }
  }

  .OrderRefundModal {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .OrderRefundModal__heading {
      text-align: center;
      margin-bottom: 0;
      font-weight: 700;
    }

    .OrderRefundModal__topCopy {
      text-align: center;
    }

    .OrderRefundModal__reasonList {
      margin-bottom: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .OrderRefundModal__reasonList__item {
        label {
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }

        input[type="radio"] {
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          appearance: none;
          -webkit-appearance: none;
          border: 1px solid $grey-4;
          text-align: center;
          margin: 0 10px 0 0;
          cursor: pointer;
          border-radius: 50%;
          outline: none;

          &:checked {
            border: 1px solid var(--brand-core, $primary-core);
            background-color: transparent;
          }

          &:checked::before {
            content: '';
            width: 12px;
            height: 12px;
            top: 3px;
            left: 3px;
            display: block;
            position: absolute;
            border-radius: 50%;
            background-image: radial-gradient(var(--brand-core, $primary-core), var(--brand-core, $primary-core));
          }

          &:hover {
            border-color: var(--brand-core, $primary-core) !important;
          }
        }

        .OrderRefundModal__reasonList__item__radio {
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          border-color: $grey-4;
        }
      }
    }

    .OrderRefundModal__notice {
      font-style: italic;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    .OrderRefundModal__buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    .OrderRefundModal__button {
      border-radius: $border-radius-sm;
      font-size: 0.875rem;
      padding: 0.375rem 0.75rem;
      border: 1px solid transparent;
      line-height: 1.6;
      transition: all 0.15s ease-in-out;
      width: 100%;
      text-transform: uppercase;

      &--cancel {
        background-color: transparent;
        border-color: var(--brand-core, $primary-core);
        color: var(--brand-core, $primary-core);
        font-weight: 400;
      }

      &--submit {
        background-color: var(--brand-core, $primary-core);
        border-color: var(--brand-core, $primary-core);
        color: var(--brand-font-color, $text-black);
        font-weight: 700;
      }
    }
  }
}

.mystery-test-modal,
.summer-sale-modal,
.MysteryProductDetailsModal {
  position: relative;

  @include media-breakpoint-down(xs) {
    width: 360px !important;
    margin: 0.5rem auto !important;
  }

  @include media-breakpoint-up(md) {
    max-width: 600px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 800px !important;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 983px !important;
  }

  .modal-content {
    padding: 0;

    .modal-body {
      padding: 0 !important;
    }

    .MysteryReveal {
      margin: 0;
    }

    .close-button {
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 30;
      margin: 5px;

      svg {
        color: $white !important;
      }
    }

    .close-button-container {
      svg {
        color: $white !important;
      }
    }
  }
}

// .mystery-test-modal {
//   .modal-content {
//     .close-button {
//       svg {
//         color: $grey-5 !important;
//       }
//     }

//     .close-button-container {
//       svg {
//         color: $grey-5 !important;
//       }
//     }
//   }
// }

.summer-sale-modal {
  @include media-breakpoint-up(xxl) {
    max-width: 800px !important;
  }
}

.MysteryProductDetailsModal {
  .modal-content {
    .close-button {
      svg {
        color: $grey-5 !important;
      }
    }

    .close-button-container {
      svg {
        color: $grey-5 !important;
      }
    }
  }
}
