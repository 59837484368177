@import '../../../styles/_chunk';

.product-message-banner {
  background-color: $grey-16;
  border-radius: $border-radius;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  @include media-breakpoint-up(lg) {
    padding: 1rem;
  }

  @include theme(light) {
    background-color: var(--card-background-color);
    box-shadow: var(--card-box-shadow);
  }

  .icon-container {
    margin-right: 1rem;
  }

  .message-container {
    .message-title {
      font-weight: 700;
    }

    a {
      color: $text-white;
      text-decoration: underline;

      @include theme(light) {
        color: var(--site-font-color);
      }

      &:hover {
        color: var(--brand-core, $primary-core);
      }
    }
  }

  &.dlc {
    padding: 0.5rem 1rem;
  }

  &.multiple-banners {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
