@import "Styles/_chunk";

.SiteSlider {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-down(xs) {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .SiteSlider__slidesContainer {
    overflow-x: hidden;
  }

  .SiteSlider__slides {
    position: relative;
    display: flex;
    flex-wrap: nowrap !important;
    gap: var(--gutter-gap);
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      overflow-x: visible;
      padding-bottom: 0;
    }
  }

  .SiteSlider__slide {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    scroll-snap-align: start;

    &:first-of-type {
      @include media-breakpoint-down(xs) {
        margin-left: 1rem;
      }
    }

    &:last-of-type {
      @include media-breakpoint-down(xs) {
        margin-right: 1rem;
      }
    }

    &--hidden {
      visibility: hidden;
    }

    .HitCardContainer {
      width: 100%;

      .HitCard {
        width: 100%;
      }
    }
  }

  // Arrows
  .SiteSlider__arrow {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
      left: 0;
      margin-left: -36px;

      @include media-breakpoint-up(xl) {
        margin-left: -41px;
      }
    }

    &--right {
      right: 0;
      margin-right: -36px;

      @include media-breakpoint-up(xl) {
        margin-right: -41px;
      }
    }

    button {
      width: 30px;
      height: 30px;
      z-index: 2;
      border: 0;
      padding: 0;
      transition: all ease 0.2s;
      background-color: transparent;
      font-size: 1rem;
      cursor: pointer;
      outline: 0;
      border-radius: 50%;
      color: $white;

      &:hover {
        color: $white;
      }

      @include theme(light) {
        color: $grey-5;

        &:hover {
          color: $grey-7;
        }
      }
    }

    button:disabled,
    button[disabled] {
      color: $grey-7;

      &:hover {
        color: $grey-7;
      }

      @include theme(light) {
        color: $grey-3;

        &:hover {
          color: $grey-3;
        }
      }
    }
  }

  // Dots
  .SiteSlider__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;

    button.SiteSlider__dots__dot,
    li.SiteSlider__dots__dot {
      border: 0;
      margin: 0;
      padding: 4px 2px;
      background: transparent;
      cursor: pointer;
      line-height: 12px;

      > div {
        width: 10px;
        height: 10px;
        background-color: $grey-7;
        border-radius: 50%;

        @include theme(light) {
          background-color: $grey-4;
        }
      }

      &--active {
        > div {
          background-color: $white;

          @include theme(light) {
            background-color: $grey-7;
          }
        }
      }

      &--hidden {
        display: none;
      }
    }
  }

  &--dots {
    margin-bottom: calc(-1rem - 10px - 4px); // Minus gap between slider and dots, height of dot, margin top on dot

    .SiteSlider__arrow {
      height: calc(100% - 1rem - 10px - 4px) !important; // Minus gap between slider and dots, height of dot, margin top on dot
    }
  }
}
