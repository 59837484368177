.lang-pt {

  // GLOBAL
  .cart-btn {
    b,
    p {
      font-size: 0.875rem;

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }

  // NAVBAR
  .secondary-navbar {
    .secondary-nav-link {
      @include media-breakpoint-only(lg) {
        padding: 0.5rem;
      }
    }
  }
}
