@import "Styles/_chunk";

.small-usp-bar {
  background-color: $grey-3;
  color: $text-black;
  font-weight: 700;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  // z-index: 399;
  font-size: 0.875rem;

  @include media-breakpoint-down(lg) {
    font-size: 0.8125rem;
  }

  @include theme(light) {
    background-color: $grey-2;
  }

  &.product-usp-bar {
    position: absolute;
  }

  .container {
    height: 100%;
  }

  .usp-distance {
    max-width: 345px;
    flex-grow: 1;
    // width: max-content;

    @include media-breakpoint-down(xl) {
      // flex-grow: 0;
    }
  }

  .points-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .geo-message-container {
      display: flex;
      font-size: 13px;
      align-items: center;

      @include media-breakpoint-up(xxl) {
        font-size: 14px;
      }

      a {
        color: $text-black;
      }
    }

    .ksp {
      width: 28%;
      flex-shrink: 0;

      img {
        max-width: 100%;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      .message-container {
        font-size: 13px;
        line-height: 18px;

        @include media-breakpoint-up(xxl) {
          font-size: 14px;
        }

        a {
          color: $text-black;
        }
      }

      &.official-keys {
        display: flex;
        align-items: center;

        .trust-tick-icon {
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;

          svg {
            color: $black;
          }
        }
      }

      &.trustpilot {
        width: 44%;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-container {
          width: 200px;
          margin-bottom: 0.5rem;
          margin-right: 0.5rem;

          .trust-pilot-img {
            width: 200px;
            height: 23px;
          }
        }
      }

      &.guarantee {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .trust-tick-icon {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;

          svg {
            color: $black;
          }
        }

        .happy-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
          font-size: 20px;
        }
      }
    }
  }

  .small-usp-bar.product-usp-bar {
    position: absolute;
  }

  .small-usp-bar-mobile.product-usp-bar {
    position: absolute;
    z-index: 400;
  }

  .small-usp-bar-mobile {
    align-items: center;
    color: #212121;
    display: flex;
    font-weight: 700;
    left: 0;
    min-height: 44px;
    padding: 6px 0;
    top: 0;
    width: 100%;
  }
}

.container-200 {
  @include media-breakpoint-up(lg) {
    max-width: 200px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 200px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 100%;
  }
}

.MobileUspBar {
  position: relative;

  .MobileUspBar__message {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &--visible {
      opacity: 1;
    }

    .MobileUspBar__message__content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  }
}
