.lang-it {

  // GLOBAL
  .cart-btn {
    b,
    p {
      font-size: 0.875rem;

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }
}
