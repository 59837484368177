.bundle-page {
  .product-message-banner {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }

  .bundle-games-container {
    margin-top: 0;
    position: relative;

    .tier-title {
      margin-bottom: 1rem;
      margin-top: 1rem;
      position: relative;
    }

    .tier:first-of-type {
      .tier-title {
        margin-top: 0;
      }
    }
  }

  .name-timer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      min-height: 57px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0.75rem;
      min-height: 61px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 65px;
    }
  }

  .name-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .product-name {
    margin-bottom: 0;
  }

  .charity-read-more {
    font-weight: 400;
    margin-bottom: 2rem;
    padding: 1rem;
    font-size: 1rem;
    border-radius: $border-radius-sm;
    position: relative;
    background-color: var(--site-background-color-secondary);

    .charity-read-more-button {
      color: var(--brand-core, $primary-core);
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .bundle-header {
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    position: relative;

    @include media-breakpoint-up(md) {
      padding-bottom: 3rem;
    }

    .header-content-container {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .cover-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @include theme(light) {
          box-shadow: var(--card-box-shadow);
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
          border-radius: $border-radius;
          align-self: flex-start;
        }

        .video-wrapper,
        .product-video-container {
          width: 100%;
        }

        .product-video-icon-container {
          opacity: 0.9;

          &:hover {
            opacity: 1;
          }
        }

        img,
        .responsive-image {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;

          @include media-breakpoint-up(lg) {
            border-radius: $border-radius;
          }
        }
      }

      .details-container {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: var(--card-background-color);
        padding: 1rem;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        @include theme(light) {
          box-shadow: var(--card-box-shadow);
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
          padding: 0 0 0 1.5rem;
          border-bottom-left-radius: 0;
          border-top-right-radius: $border-radius;
          background-color: transparent;

          @include theme(light) {
            background-color: transparent;
            box-shadow: none;
          }
        }

        @include media-breakpoint-up(xl) {
          padding-left: 2rem;
        }

        .bundle-label {
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
          }

          .bundle-label-content {
            display: flex;
            min-height: 25px;

            .bundle-label-content-text {
              padding: 3px 6px;
              padding-right: 0;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
              letter-spacing: 0.1px;
              color: $text-black;
              background: $white;
              display: flex;
            }

            .bundle-label-content-slash {
              width: 20px;
              background: linear-gradient(-66deg, transparent 0%, transparent 49%, $white 51%, $white 100%);
            }
          }

          &--lowstock {
            .bundle-label-content {
              .bundle-label-content-text {
                color: $white;
                background: $label-red;
              }

              .bundle-label-content-slash {
                background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-red 51%, $label-red 100%);
              }
            }
          }
        }

        .icons-wishlist-container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
          }
        }

        .icons-container {
          display: flex;
          align-items: center;

          @include theme(light) {
            color: var(--card-icons-color);
          }
        }

        .drm-container {
          margin-right: 1rem;
        }

        .platforms-container {
          font-size: 13px;
          display: flex;

          @include theme(light) {
            color: var(--card-icons-color);
          }

          span {
            margin-right: 4px;

            @include media-breakpoint-up(xl) {
              margin-right: 5px;
            }
          }
        }

        .price-container {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          .approx-native-price {
            color: var(--brand-core, $primary-core);
          }
        }

        .slider {
          .rangeslider__handle-tooltip {
            display: none;
          }
        }

        .price {
          color: var(--brand-core, $primary-core);
          font-size: 1.5rem;
          font-weight: 700;
          text-transform: uppercase;
        }

        .tiered-mystery-price {
          color: var(--brand-core, $primary-core);
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;

          @include media-breakpoint-up(md) {
            font-size: 1.375rem;
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.5rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.625rem;
          }
        }

        .mystery-price {
          color: var(--brand-core, $primary-core);
          font-size: 1.5rem;
          font-weight: 700;
          text-transform: uppercase;
          display: block;

          @include media-breakpoint-up(lg) {
            font-size: 1.625rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.75rem;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 1.875rem;
          }
        }

        .was-price {
          font-size: 1.5rem;
          color: $grey-5;
          position: relative;
          text-decoration: line-through;
          margin-left: 0.625rem;
        }

        .saving-percentage {
          height: 40px;
          min-width: 60px;
          font-size: 1.125rem;
          line-height: 1.125rem;
          color: var(--site-font-color);
          background-color: var(--percentage-background-color);
          margin-left: 1rem;
          display: flex;
          align-items: center;
          border-radius: $border-radius-sm;
          flex-direction: column;
          justify-content: center;

          .tiered-text {
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            margin-bottom: 1px;
          }
        }

        .product-giveaway-newsletter-required {
          margin-bottom: 1rem;

          @include media-breakpoint-up(xl) {
            width: 80%;
          }

          @include media-breakpoint-up(xxl) {
            width: 75%;
          }

          .subscribe-area {
            @include theme(dark) {
              @include media-breakpoint-up(lg) {
                background-color: rgba($black, 0.75);
              }
            }
          }

          &.sold-out {
            .subscribe-area {
              background-color: transparent;
            }
          }
        }

        .tier-selection-container {
          margin-bottom: 20px;
        }

        .mystery-slider-container {
          position: relative;

          .rangeslider-horizontal {
            .rangeslider__fill {
              background-color: var(--brand-core, $primary-core);
            }
          }
        }

        .tier-selection {
          width: 100%;
          border-radius: $border-radius-sm;
          margin-bottom: 12px !important;

          @include media-breakpoint-up(lg) {
            width: 420px;
            background-color: $card-background-color-dark;
            padding: 0.5rem 1rem;
            margin-bottom: 4px !important;

            @include theme(light) {
              background-color: $grey-2;
            }
          }

          @include media-breakpoint-up(xl) {
            width: 450px;
          }

          @include media-breakpoint-up(xxl) {
            width: 480px;
          }

          .form-check-label {
            width: 100%;
            padding: 0;
            display: flex;
            align-items: center;
          }

          input {
            flex-shrink: 0;
          }

          .selection-label {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-weight: 400;
          }

          .tier-price {
            font-weight: 700;
            display: flex;
            margin-left: 0.5rem;

            .full-price {
              color: $grey-5;
              text-decoration: line-through;
              margin-right: 0.75rem;
            }

            .free-text {
              text-transform: uppercase;
            }
          }

          input[type="radio"] {
            display: inline-block;
            float: left;
            width: 20px;
            height: 20px;
            position: relative;
            appearance: none;
            -webkit-appearance: none;
            border: 1px solid $grey-5;
            text-align: center;
            margin: 0 10px 0 0;
            cursor: pointer;
            border-radius: 50%;
            outline: none;

            @include theme(light) {
              border-color: $grey-4;
            }

            &:checked {
              border: 1px solid var(--brand-core, $primary-core);
              background-color: transparent;
            }

            &:checked::before {
              content: '';
              width: 12px;
              height: 12px;
              top: 3px;
              left: 3px;
              display: block;
              position: absolute;
              border-radius: 50%;
              background-image: radial-gradient(var(--brand-core, $primary-core), var(--brand-core, $primary-core));
            }

            &:hover {
              border-color: var(--brand-core, $primary-core) !important;
            }
          }
        }

        .tier-selection-giveaway-width {
          width: 430px;
        }

        .product-ways-to-pay {
          padding: 0;
        }

        .keys-container {
          margin: 1.5rem 0;

          @include media-breakpoint-up(xl) {
            width: 90%;
          }

          @include media-breakpoint-up(xxl) {
            width: 80%;
          }

          .progress {
            @include theme(dark) {
              background-color: $grey-12;
            }
            @include theme(light) {
              background-color: $grey-3;
            }
          }
        }

        .BundlePage__label {
          position: relative;
          margin-bottom: 0.5rem;

          @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
          }

          .BundlePage__label__content {
            display: flex;
            min-height: 25px;

            .BundlePage__label__content__text {
              padding: 3px 6px;
              padding-right: 0;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
              letter-spacing: 0.1px;
              color: $text-black;
              background: $white;
              display: flex;

              img {
                width: 85px;
              }
            }

            .BundlePage__label__content__slash {
              width: 20px;
              background: linear-gradient(-66deg, transparent 0%, transparent 49%, $white 51%, $white 100%);
            }
          }

          .BundlePage__label__triangle {
            width: 0;
            height: 0;
            border-top: 5px solid $white;
            border-left: 5px solid transparent;
            display: none;
          }

          &--flashdeal {
            .BundlePage__label__content {
              .BundlePage__label__content__text {
                color: $white;
                background: $label-red;
              }

              .BundlePage__label__content__slash {
                background: linear-gradient(-66deg, transparent 0%, transparent 49%, $label-red 51%, $label-red 100%);
              }
            }

            .BundlePage__label__triangle {
              border-top-color: $label-red-dark;
            }
          }
        }
      }

      .pwyw-action-buttons-container {
        display: flex;
        flex-direction: column;

        .cart-btn {
          display: flex !important;
        }

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          align-items: baseline;
        }
      }

      .edit-price-button {
        color: var(--brand-core, $primary-core);
        text-decoration: none;
        padding: 0.1rem 0 0 0;
        margin-bottom: -10px;
        white-space: nowrap;
        align-self: flex-start;
        margin-top: 3px;

        @include media-breakpoint-up(lg) {
          margin-left: 1rem;
          margin-top: 0;
          align-self: inherit;
        }
      }

      .button-wishlist-container {
        display: flex;

        .cart-buttons-container {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-up(xxl) {
            flex-direction: row;
          }
        }

        .cart-btn {
          width: 100%;

          @include media-breakpoint-up(md) {
            min-width: 180px;
          }

          @include media-breakpoint-up(lg) {
            min-width: 210px;
          }

          .btn-text-container {
            b {
              flex-grow: 0;
            }
          }
        }

        .presale-wishlist-btn {
          @include media-breakpoint-up(lg) {
            width: 350px;
          }

          @include media-breakpoint-up(xxl) {
            width: 380px;
          }
        }

        .ProductAddToCartBtnContainer {
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: auto;
          }
        }

        .ProductAddToCartButton {
          width: 100%;

          @include media-breakpoint-up(lg) {
            min-width: 300px;
          }
        }
      }

      .cart-btn {
        margin: 0 !important;
      }

      .heart-container {
        background-color: $card-background-color-dark;
        border-color: $card-background-color-dark;
        padding: 0.813rem;
      }

      .product-soldout {
        background-color: transparent;
        margin-bottom: 15px;
        color: $error-core;
        font-weight: 400;
      }

      .region-locked {
        background: transparent;
        margin-top: 1rem;
        width: 100%;

        @include media-breakpoint-up(xl) {
          width: 450px;
        }

        .region-locked-content {
          padding: 0;

          .btn {
            padding-left: 0;
            padding-bottom: 0;
            font-size: 0.8125rem;

            @include media-breakpoint-up(xl) {
              font-size: 0.875rem;
            }
          }
        }
      }

      .ebooks-worth-value {
        margin-top: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
  }

  .bundle-product-recommendations {
    .recommendation-container {
      h3 {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
          font-size: 1.375rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.625rem;
        }
      }

      .HitCard {
        &.card-white-theme {
          box-shadow: var(--card-box-shadow);
        }
      }
    }
  }

  .description-age-container {
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    .product-description {
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 100%;
      flex-grow: 1;

      a {
        color: var(--brand-core, $primary-core);
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 2.5rem;
      }
    }

    .description-right-column {
      flex-shrink: 0;

      @include media-breakpoint-up(lg) {
        margin-left: 1.75rem;
        width: 298px;
      }

      @include media-breakpoint-up(xl) {
        width: 357px;
      }

      @include media-breakpoint-up(xxl) {
        width: 422px;
      }

      .bundle-agerating {
        margin-bottom: 1.75rem;
      }

      .donation-section {
        margin-bottom: 1.75rem;
        margin-top: 1rem;

        .donation-section-message {
          padding-bottom: 1rem;
        }

        .donation-list-container {
          overflow-y: scroll;
          max-height: 380px;
          position: relative;

          &::after {
            content: '';
            display: block;
            position: sticky;
            bottom: 0;
            right: 0;
            left: 0;
            height: 60px;
            background: linear-gradient(to top, $grey-14, rgba($grey-14, 0));

            @include theme(light) {
              background: linear-gradient(to top, var(--site-background-color), rgba(228, 231, 237, 0));
            }
          }

          .donation-name {
            font-weight: 400;
            font-size: 16px;

            @include media-breakpoint-up(lg) {
              font-size: 18px;
            }
          }
        }
      }
    }

    .description-right-column-extra-margin {
      margin-left: 5rem;
    }

    .preorder-bottom-border {
      border-bottom: 2px solid $grey-12;
      margin-bottom: 1rem;

      @include theme(light) {
        border-color: $grey-4;
      }
    }
  }

  .product-description {
    margin-top: 1rem;
    margin-bottom: 2rem;

    a {
      color: var(--brand-core, $primary-core);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
  }

  .product-desc-container {
    .product-description {
      margin-bottom: 0;
    }
  }

  .bundle-mobile-timer-container {
    background-color: $card-background-color-dark;
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: $grey-2;
    }
  }

  .bundle-agerating {
    margin-bottom: 2rem;
    width: 100%;
    flex-shrink: 0;

    .ageratings-container {
      height: 74px;
      padding: 0.75rem;
    }

    img {
      height: 50px;
      margin: 0.25rem;
      width: auto;

      @include media-breakpoint-up(lg) {
        height: 40px;
      }

      @include media-breakpoint-up(xl) {
        height: 50px;
      }
    }
  }
}

// Extra style changes for the bundles with a background

.bundle-page.background-bundle {
  .header-content-container {
    .details-container {
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }
}

.bundle-wishlist-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  .text-container {
    margin-left: 0.5rem;
    font-size: 0.8125rem;
    text-align: left;

    @include theme(light) {
      color: var(--site-font-color);
      font-weight: 400;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      font-size: 0.875rem;
    }
  }
}

.bundle-wishlist-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-white;

  @include media-breakpoint-down(md) {
    height: 3rem;
    width: 3rem;
    border: 1px solid var(--brand-core, $primary-core);
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;

    .heart-container {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }

    svg {
      width: 22px;
      height: 22px;
      color: var(--brand-core, $primary-core);
      background-color: transparent;
      padding: 0;
      margin: 0;
    }
  }

  &:hover {
    color: var(--brand-core, $primary-core);
    text-decoration: none;

    svg {
      color: var(--brand-core, $primary-core);
    }
  }

  &:focus {
    text-decoration: none;
  }

  &.in-wishlist {
    svg {
      color: var(--brand-core, $primary-core);
    }
  }
}

.bundle-details-modal {
  max-width: 100%;

  @include media-breakpoint-down(sm) {
    margin: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    display: block;
    margin: 1.75rem auto;
  }

  @include media-breakpoint-up(sm) {
    max-width: 524px;
  }

  @include media-breakpoint-up(md) {
    max-width: 704px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 944px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1124px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1324px;
  }

  .modal-content {
    .modal-body {
      // padding: 0;
    }
  }

  .bundle-carousel {
    max-width: 100%;

    .carousel-title-container {
      margin-top: 1rem;

      .section-title {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 1.5rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.75rem;
        }
      }
    }

    .game-carousel {
      margin-bottom: 0;
      border-radius: 0;

      @include media-breakpoint-up(lg) {
        height: 309px;
      }

      @include media-breakpoint-up(xl) {
        height: 363px;
      }

      @include media-breakpoint-up(xxl) {
        height: 424px;
      }

      .video-container {
        border-radius: 0;
      }
    }

    .carousel-buttons-below {
      display: none;
    }
  }
}
