.slick-slider {
  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-active .mobile-friendly-dots {
    height: 10px;
    width: 10px;
    background: $grey-1;
    border-radius: 50%;
    display: inline-block;
  }

  .mobile-friendly-dots {
    height: 10px;
    width: 10px;
    background: $grey-7;
    border-radius: 50%;
    display: inline-block;
  }

  .arrow-btn-container {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &.btn-right {
      right: 0;
      margin-right: -20px;

      @include media-breakpoint-up(xl) {
        margin-right: -25px;
      }
    }

    &.btn-left {
      left: 0;
      margin-left: -20px;

      @include media-breakpoint-up(xl) {
        margin-left: -25px;
      }
    }

    .slick-arrow {
      border: 0;
      color: $white;
      padding: 0;
      transition: all ease 0.2s;
      z-index: 2;
      background-color: transparent;
      width: 30px;
      height: 30px;
      font-size: 1rem;
      cursor: pointer;
      outline: 0;
      border-radius: 50%;

      &:hover {
        color: $white;
      }
    }

    .slick-disabled {
      color: $grey-7;

      &:focus,
      :hover,
      :active {
        color: $grey-7;
      }
    }
  }

  ul.slick-dots {
    display: inline-block;
    list-style: none;
    -moz-padding-start: 0;
    -webkit-padding-start: 0;
    position: absolute;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-align: center;

    li {
      line-height: 12px;
      padding: 4px 2px;
      display: inline-block;
    }

    button {
      color: transparent;
      border: 0;
      border-radius: 50%;
      background-color: $grey-7;
      height: 10px;
      width: 10px;
      margin: 0 1px;
      padding: 0;

      @include media-breakpoint-up(md) {
        margin: 0 2px;
        height: 12px;
        width: 12px;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        outline: none;
      }
    }

    li.slick-active {
      button {
        background-color: $white !important;
      }
    }
  }

  // -------- STYLING FOR SLIDERS ON WHITE THEME PAGES

  &.slider-light-theme {
    .arrow-btn-container {
      .slick-arrow {
        color: $grey-5;

        &:hover {
          color: $grey-7;
        }
      }

      .slick-disabled {
        color: $grey-3;

        &:focus,
        :hover,
        :active {
          color: $grey-3;
        }
      }
    }

    ul.slick-dots {
      button,
      .mobile-friendly-dots {
        background-color: $grey-4 !important;
      }

      li.slick-active {
        button,
        .mobile-friendly-dots {
          background-color: $grey-7 !important;
        }
      }
    }
  }

  @include theme(light) {
    @extend .slider-light-theme;
  }
}
