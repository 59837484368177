.react-sharing-button__link {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  color: $white;
  margin: 0.5em;
  border-radius: 3px;
  transition: 50ms ease-out;
  padding: 0.625rem 0.75rem;
  line-height: 1.2em;

  &:focus,
  &:hover,
  &:active {
    color: $white;
    text-decoration: none;
  }
}

.react-sharing-button__icon {
  fill: $white;
  stroke-width: 0;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.2em;
  vertical-align: bottom;
}

.react-sharing-button__text {
  padding-left: 0.4em;
  line-height: 1.2em;
}

.react-sharing-button--email {
  background-color: $grey-7;
}

.react-sharing-button--email:hover {
  background-color: $grey-10;
}

.react-sharing-button--facebook {
  background-color: $facebook-color;
}

.react-sharing-button--facebook:hover {
  background-color: darken($facebook-color, 8%);
}

.react-sharing-button--reddit {
  background-color: $reddit-color;
}

.react-sharing-button--reddit:hover {
  background-color: darken($reddit-color, 8%);
}

.react-sharing-button--twitter {
  background-color: $twitter-color;
}

.react-sharing-button--twitter:hover {
  background-color: darksn($twitter-color, 8%);
}

.share-btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  .react-sharing-button__link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4rem;
    margin-left: 0.4rem;

    @include media-breakpoint-up(lg) {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .react-sharing-button__text {
    display: none;
  }
}
