@import "Styles/_chunk";

.product-cover-container {
  overflow: hidden;

  .responsive-image {
    overflow: hidden;
  }

  &.portrait-cover,
  &.unknown-cover {
    .responsive-image {
      background-color: transparent;
      background-image: none;
    }
  }

  &.thumbnail-cover {
    .responsive-image {
      img {
        object-fit: cover;
      }
    }

    .cover-image-background {
      filter: blur(6px) brightness(0.75);
      transform: scale(1.2);
    }
  }
}

.cover-image-foreground {
  position: relative;
  object-fit: contain !important;
  height: 100%;
  z-index: 2;
}

.cover-image-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute !important;
  filter: blur(18px) brightness(0.75);
  transform: scale(1.1);
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover !important;
}
