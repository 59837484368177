.address-form-billing-details-title {
  display: none;
}

.address-form-container {
  .address-form {
    .form-group {
      width: 100%;

      input {
        border-radius: 5px;
      }

      .form-control {
        min-height: 40px;
      }

      .input-error {
        border-color: $error-core !important;
        background-color: $red-50;
      }

      label {
        small {
          margin-left: 0.5rem;
          font-weight: 400;
          color: $font-color-muted;
        }
      }
    }

    .address-field-error {
      margin-top: 0.375rem;
      font-size: 14px;
      color: $error-core;
      font-weight: 700;
    }

    .address-search-separator {
      margin: 2rem 0 1.5rem 0;
      height: 2px;
      background-color: $grey-10;
    }

    .manual-address-text {
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }
}
