@import "Styles/_chunk";

.region-locked {
  .region-locked-title {
    background-color: $grey-14;
    padding: 0.75rem 1rem;
    font-weight: 400;
  }

  .region-locked-content {
    display: flex;
    align-items: center;
    font-size: var(--site-font-size-small);

    .btn {
      white-space: normal;
      text-align: left;
      font-weight: lighter;
      text-decoration: none;
      line-height: 1.2rem;
      color: $text-white;
      padding: 0;
      font-size: 0.8125rem;

      @include theme(light) {
        color: var(--site-font-color);
        font-weight: 400;
      }

      @include media-breakpoint-up(xl) {
        font-size: 0.875rem;
      }

      &:hover {
        background-color: transparent;
        border-color: transparent;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .toggle-restrictions-button {
      color: var(--brand-core, $primary-core);
      background: none;
      border: none;
      padding: 0;
      font-weight: unset;
      outline: none;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        text-decoration: none;
        cursor: pointer;
      }

      svg {
        color: $white;
        @include theme(light) {
          color: $grey-5;
        }
      }
    }

    .region-name {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;

      .region-flag {
        width: 42px;
        height: 35px;
        padding-right: 10px;
        padding-bottom: 3px;
      }

      .notification-icon {
        margin-right: 8px;
        position: relative;
        width: 20px;
        height: 20px;
      }

      .icon-container {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        .fa-times-circle {
          color: $error-core;
          width: 20px;
          height: 20px;
        }
      }

      .background-circle {
        background-color: $white;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        margin-top: 1px;
        margin-left: 1px;
      }
    }
  }

  .region-close-button {
    color: $grey-4;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    display: flex;
    width: 100%;
    border: none;
    background: none;
  }

  .tick-icon {
    margin-top: -3px;
  }
}
